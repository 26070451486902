import { Box, Typography } from '@mui/material';

import SectionTitle from './SectionTitle';

interface Props {
  disabled?: boolean;
}

const contents = [
  'Visiting Hours',
  'General visiting hours are 8:00 a.m. - 9:00 p.m. except in intensive care units, where hours vary. Please check with your nursing unit.',
];

const contents2 = [
  'Food & Nutrition Services',
  'A Food & Nutrition Services employee will bring a menu for the next day with your meal tray. This menu follows a diet prescribed by your physician and offers a variety of delicious, \nhealthful foods. Simply circle your selections and place the completed menu on your tray.',
  'A staff member will check your choices to make sure they agree with your doctor-prescribed eating plan, and will be available to discuss your menu and answer any questions. If you \nneed further assistance, nursing personnel on your unit will be happy to contact the Food & Nutrition Services Department.',
  'Occasionally, certain medical tests may be scheduled during your regular meal time, or a procedure must be performed on an empty stomach. This may delay your meal. As soon as you \nare able to eat, however, nursing personnel will order a tray for you.',
  'The Food & Nutrition Services staff includes Clinical Dieticians specifically trained in nutritional science, health maintenance and patient education. Our dieticians provide extra \nnutritional support to those patients who need it most. They are also available to teach patients about special eating plans and dietary restrictions. If you would like to learn more about \nyour diet, ask your doctor or nurse to contact a dietitian.',
  'If there is something we could be doing better, or if you have questions or concerns, please let us know by calling the Patient Food Service Help Line at 0000-0000.',
];

const contents3 = [
  'Personal items',
  "There is no provision for the storage of belongings while you are in the hospital. All personal belongings will be the responsibility of the patient/family. All belongings (including \neyeglasses, hearing aids, dentures, etc.) must be removed from the patient's room should the patient be transferred to an operating area or intensive care unit. If you need to store \nsomething in the hospital vault, please call Admitting at 0000-0000.",
];

const contents4 = [
  'Checking Out',
  'When your doctor has discharged you and you are ready to leave, you will be escorted out in a wheelchair by hospital staff.',
  "Before you leave, don't forget to check your room carefully for any personal belongings. and also, to pick up any valuables you stored in the hospital vault. Hospital check-out time is 11:00 \na.m.",
  'Before leaving, you must be cleared by the Business Office: South Tower, first floor, 8:00 a.m. - 4:30 p.m., 0000-0000.',
];

const TwiceContents = (props: Props) => {
  const { disabled } = props;

  return (
    <>
      <SectionTitle title="While you are here" />

      <Box
        sx={{
          width: '98%',
          margin: '48px auto 24px auto',
          border: '1px solid lightgray',
          padding: '10px',
        }}
      >
        {contents.map((v, i) => {
          if (i === 0) {
            return (
              <Typography
                sx={{
                  fontWeight: '500',
                  fontSize: '12px',
                  lineHeight: '24px',
                  whiteSpace: 'pre-wrap',
                }}
              >
                {v}
              </Typography>
            );
          }
          if (i === contents.length - 1) {
            return (
              <Typography
                sx={{
                  fontWeight: '400',
                  fontSize: '14px',
                  lineHeight: '24px',
                  whiteSpace: 'pre-wrap',
                }}
              >
                {v}
              </Typography>
            );
          }
          return (
            <Typography
              sx={{
                fontWeight: '400',
                fontSize: '14px',
                lineHeight: '24px',
                whiteSpace: 'pre-wrap',
              }}
            >
              {v}
              <p />
            </Typography>
          );
        })}
      </Box>

      <Box
        sx={{
          width: '98%',
          margin: '48px auto 24px auto',
          border: '1px solid lightgray',
          padding: '10px',
        }}
      >
        {contents2.map((v, i) => {
          if (i === 0) {
            return (
              <Typography
                sx={{
                  fontWeight: '500',
                  fontSize: '12px',
                  lineHeight: '24px',
                  whiteSpace: 'pre-wrap',
                }}
              >
                {v}
              </Typography>
            );
          }
          if (i === contents2.length - 1) {
            return (
              <Typography
                sx={{
                  fontWeight: '400',
                  fontSize: '14px',
                  lineHeight: '24px',
                  whiteSpace: 'pre-wrap',
                }}
              >
                {v}
              </Typography>
            );
          }
          return (
            <Typography
              sx={{
                fontWeight: '400',
                fontSize: '14px',
                lineHeight: '24px',
                whiteSpace: 'pre-wrap',
              }}
            >
              {v}
              <p />
            </Typography>
          );
        })}
      </Box>

      <Box
        sx={{
          width: '98%',
          margin: '48px auto 24px auto',
          border: '1px solid lightgray',
          padding: '10px',
        }}
      >
        {contents3.map((v, i) => {
          if (i === 0) {
            return (
              <Typography
                sx={{
                  fontWeight: '500',
                  fontSize: '12px',
                  lineHeight: '24px',
                  whiteSpace: 'pre-wrap',
                }}
              >
                {v}
              </Typography>
            );
          }
          if (i === contents3.length - 1) {
            return (
              <Typography
                sx={{
                  fontWeight: '400',
                  fontSize: '14px',
                  lineHeight: '24px',
                  whiteSpace: 'pre-wrap',
                }}
              >
                {v}
              </Typography>
            );
          }
          return (
            <Typography
              sx={{
                fontWeight: '400',
                fontSize: '14px',
                lineHeight: '24px',
                whiteSpace: 'pre-wrap',
              }}
            >
              {v}
              <p />
            </Typography>
          );
        })}
      </Box>

      <SectionTitle title="Going Home" />

      <Box
        sx={{
          width: '98%',
          margin: '48px auto 24px auto',
          border: '1px solid lightgray',
          padding: '10px',
        }}
      >
        {contents4.map((v, i) => {
          if (i === 0) {
            return (
              <Typography
                sx={{
                  fontWeight: '500',
                  fontSize: '12px',
                  lineHeight: '24px',
                  whiteSpace: 'pre-wrap',
                }}
              >
                {v}
              </Typography>
            );
          }

          return (
            <Typography
              sx={{
                fontWeight: '400',
                fontSize: '14px',
                lineHeight: '24px',
                whiteSpace: 'pre-wrap',
              }}
            >
              {v}
            </Typography>
          );
        })}
      </Box>
    </>
  );
};

export default TwiceContents;
