import { MENU, SurveyDialogProps } from './type';

import ECardex from './ECardex';
import TakingOver from './TakingOver';
import Hospitalization from './Hospitalization';
import OutHospital from './OutHospital';
import Prescription from './Prescription';
import Nurse from './Nurse';
import Medication from './Medication';
import Radiology from './Radiology';
import Pathology from './Pathology';
import ClinicalObservation from './ClinicalObservation';
import Glucose from './Glucose';
import BedScore from './BedScore';
import Fall from './Fall';
import FallScale from './Humty Dumpty Fall Scale';
import GCS from './GCS';
import Pediatric_GCS from './Pediatric_GCS';
import FourScore from './Four Score';
import Safety from './Safety';
import Needs from './Needs';
import NRS from './Pain/NRS';
import FLACC from './Pain/FLACC';
import CNPS from './Pain/CNPS';
import FFI from './Pain/FFI';
import KOOS from './Pain/KOOS';
import LEFS from './Pain/LEFS';
import NDI from './Pain/NDI';
import STarTBackScreening from './Pain/STarTBackScreening';
import MentalNursing from './MentalHealth/MentalNursing';
import BDI from './MentalHealth/BDI';
import BAI from './MentalHealth/BAI';
import MMSE from './MentalHealth/MMSE';
import Operation from './Special/Operation';
import Anesthesia from './Special/Anesthesia';
import Transfusion from './Special/Transfusion';
import Dialysis from './Special/Dialysis';
import Emergency from './Special/Emergency';
import Childbirth from './Special/Childbirth';
import HomeCare from './Special/HomeCare';
import DietNutrition from './DietNutrition';
import FallPrevention from './Agreement/FallPrevention';
import PatientConsentForm from './Agreement/PatientConsentForm';
import TransfusionAgree from './Agreement/Transfusion_Agree';
import Conscious from './Agreement/Conscious';
import MedicalRecords from './Agreement/AdmissionCheckList';
import DNR from './Agreement/DNR';
import PatientsGuide from './Agreement/PatientsGuide';
import CoreNursingSkillVideo from 'routes/CoreNursingSkillVideo';
import CoreNursingSkillVideoExemple from 'routes/CoreNursingSkillVideoExemple';
import Admission from './Agreement/Admission';
import AdmissionGuide from './Agreement/AdmissionGuide';
import DrugCalculatior from './DrugCalculator';
import IORecord from './IORecord';
import Clinicalobs_ICU from './Clinicalobs ICU';
import Intubation from './Special/Intubation';
import CPR from './Special/CPR';
import CRRT from './Agreement/CRRT';
import CentralVenous from './Agreement/CentralVenous';
import IntubationAgreement from './Agreement/Intubation';
import Suppressor from './Agreement/Suppressor';
import SBAR from './SBAR';
import SBAR2 from './SBAR2';
import SBAR3 from './SBAR3';

interface Props {
  surveyType: string;
  onCloseSave: () => void;
  onCloseReadOnly: () => void;
  dialogProps: Omit<SurveyDialogProps<any>, 'onClose'>;
}

const DisplaySurvey = (props: Props) => {
  const { surveyType, onCloseSave, onCloseReadOnly, dialogProps } = props;

  const { defaultValues } = dialogProps;

  switch (surveyType) {
    case MENU.E_CARDEX: {
      if (!defaultValues) return null;
      return <ECardex {...dialogProps} onClose={onCloseSave} />;
    }
    case MENU.SBAR1: {
      if (!defaultValues) return null;
      return <SBAR {...dialogProps} onClose={onCloseSave} />;
    }
    case MENU.SBAR2: {
      if (!defaultValues) return null;
      return <SBAR2 {...dialogProps} onClose={onCloseSave} />;
    }
    case MENU.SBAR3: {
      if (!defaultValues) return null;
      return <SBAR3 {...dialogProps} onClose={onCloseSave} />;
    }
    case MENU.TAKING_OVER: {
      if (!defaultValues) return null;
      return <TakingOver {...dialogProps} onClose={onCloseSave} />;
    }
    case MENU.HOSPITALIZATION: {
      if (!defaultValues) return null;
      return <Hospitalization {...dialogProps} onClose={onCloseSave} />;
    }
    case MENU.OUT_HOSPITAL: {
      if (!defaultValues) return null;
      return <OutHospital {...dialogProps} onClose={onCloseSave} />;
    }
    case MENU.PRESCRIPTION: {
      return <Prescription {...dialogProps} onClose={onCloseReadOnly} />;
    }
    case MENU.NURSE: {
      return <Nurse {...dialogProps} onClose={onCloseReadOnly} />;
    }
    case MENU.MEDICATION: {
      if (!defaultValues) return null;
      return <Medication {...dialogProps} onClose={onCloseSave} />;
    }
    case MENU.RADIOLOGY: {
      if (!defaultValues) return null;
      return <Radiology {...dialogProps} onClose={onCloseReadOnly} />;
    }
    case MENU.PATHOLOGY: {
      if (!defaultValues) return null;
      return <Pathology {...dialogProps} onClose={onCloseReadOnly} />;
    }
    case MENU.CLINICAL_OBSERVATION: {
      if (!defaultValues) return null;
      return <ClinicalObservation {...dialogProps} onClose={onCloseSave} />;
    }
    case MENU.CLINICAL_OBSERVATION_ICU: {
      if (!defaultValues) return null;
      return <Clinicalobs_ICU {...dialogProps} onClose={onCloseSave} />;
    }
    case MENU.IORECORD: {
      if (!defaultValues) return null;
      return <IORecord {...dialogProps} onClose={onCloseSave} />;
    }
    case MENU.GLUCOSE: {
      if (!defaultValues) return null;
      return <Glucose {...dialogProps} onClose={onCloseSave} />;
    }
    case MENU.BEDSORES: {
      if (!defaultValues) return null;
      return <BedScore {...dialogProps} onClose={onCloseSave} />;
    }
    case MENU.NEEDS: {
      if (!defaultValues) return null;
      return <Needs {...dialogProps} onClose={onCloseSave} />;
    }
    case MENU.FALL: {
      if (!defaultValues) return null;
      return <Fall {...dialogProps} onClose={onCloseSave} />;
    }
    case MENU.FALLSCALE: {
      if (!defaultValues) return null;
      return <FallScale {...dialogProps} onClose={onCloseSave} />;
    }
    case MENU.GCS: {
      if (!defaultValues) return null;
      return <GCS {...dialogProps} onClose={onCloseSave} />;
    }
    case MENU.Pediatric_GCS: {
      if (!defaultValues) return null;
      return <Pediatric_GCS {...dialogProps} onClose={onCloseSave} />;
    }
    case MENU.FourScore: {
      if (!defaultValues) return null;
      return <FourScore {...dialogProps} onClose={onCloseSave} />;
    }
    case MENU.SAFETY: {
      if (!defaultValues) return null;
      return <Safety {...dialogProps} onClose={onCloseSave} />;
    }
    case MENU.NRS: {
      if (!defaultValues) return null;
      return <NRS {...dialogProps} onClose={onCloseSave} />;
    }
    case MENU.FLACC: {
      if (!defaultValues) return null;
      return <FLACC {...dialogProps} onClose={onCloseSave} />;
    }
    case MENU.CNPS: {
      if (!defaultValues) return null;
      return <CNPS {...dialogProps} onClose={onCloseSave} />;
    }
    case MENU.FFI: {
      if (!defaultValues) return null;
      return <FFI {...dialogProps} onClose={onCloseSave} />;
    }
    case MENU.KOOS: {
      if (!defaultValues) return null;
      return <KOOS {...dialogProps} onClose={onCloseSave} />;
    }
    case MENU.LEFS: {
      if (!defaultValues) return null;
      return <LEFS {...dialogProps} onClose={onCloseSave} />;
    }
    case MENU.NDI: {
      if (!defaultValues) return null;
      return <NDI {...dialogProps} onClose={onCloseSave} />;
    }
    case MENU.STarT_BacknScreening: {
      if (!defaultValues) return null;
      return <STarTBackScreening {...dialogProps} onClose={onCloseSave} />;
    }
    case MENU.MENTAL_NURSING: {
      if (!defaultValues) return null;
      return <MentalNursing {...dialogProps} onClose={onCloseSave} />;
    }
    case MENU.BDI: {
      if (!defaultValues) return null;
      return <BDI {...dialogProps} onClose={onCloseSave} />;
    }
    case MENU.BAI: {
      if (!defaultValues) return null;
      return <BAI {...dialogProps} onClose={onCloseSave} />;
    }
    case MENU.MMSE: {
      if (!defaultValues) return null;
      return <MMSE {...dialogProps} onClose={onCloseSave} />;
    }
    case MENU.OPERATION: {
      if (!defaultValues) return null;
      return <Operation {...dialogProps} onClose={onCloseSave} />;
    }
    case MENU.ANESTHESIA: {
      if (!defaultValues) return null;
      return <Anesthesia {...dialogProps} onClose={onCloseSave} />;
    }
    case MENU.TRANSFUSION: {
      if (!defaultValues) return null;
      return <Transfusion {...dialogProps} onClose={onCloseSave} />;
    }
    case MENU.INTUBATION: {
      if (!defaultValues) return null;
      return <Intubation {...dialogProps} onClose={onCloseSave} />;
    }
    case MENU.CPR: {
      if (!defaultValues) return null;
      return <CPR {...dialogProps} onClose={onCloseSave} />;
    }
    case MENU.DIALYSIS: {
      if (!defaultValues) return null;
      return <Dialysis {...dialogProps} onClose={onCloseSave} />;
    }
    case MENU.EMERGENCY: {
      if (!defaultValues) return null;
      return <Emergency {...dialogProps} onClose={onCloseSave} />;
    }
    case MENU.CHILDBIRTH: {
      if (!defaultValues) return null;
      return <Childbirth {...dialogProps} onClose={onCloseSave} />;
    }
    case MENU.HOME_CARE: {
      if (!defaultValues) return null;
      return <HomeCare {...dialogProps} onClose={onCloseSave} />;
    }
    case MENU.DIET_NUTRITION: {
      if (!defaultValues) return null;
      return <DietNutrition {...dialogProps} onClose={onCloseSave} />;
    }
    case MENU.FALL_PREVENTION: {
      if (!defaultValues) return null;
      return <FallPrevention {...dialogProps} onClose={onCloseSave} />;
    }
    case MENU.PATIENTSGUIDE: {
      if (!defaultValues) return null;
      return <PatientsGuide {...dialogProps} onClose={onCloseSave} />;
    }
    case MENU.PATIENTCONSENTFORM: {
      if (!defaultValues) return null;
      return <PatientConsentForm {...dialogProps} onClose={onCloseSave} />;
    }
    case MENU.TRANSFUSION_AGREE: {
      if (!defaultValues) return null;
      return <TransfusionAgree {...dialogProps} onClose={onCloseSave} />;
    }
    case MENU.CONSCIOUS: {
      if (!defaultValues) return null;
      return <Conscious {...dialogProps} onClose={onCloseSave} />;
    }
    case MENU.DNR: {
      if (!defaultValues) return null;
      return <DNR {...dialogProps} onClose={onCloseSave} />;
    }
    case MENU.ADMISSION: {
      if (!defaultValues) return null;
      return <Admission {...dialogProps} onClose={onCloseSave} />;
    }
    case MENU.ADMISSIONGUIDE: {
      if (!defaultValues) return null;
      return <AdmissionGuide {...dialogProps} onClose={onCloseReadOnly} />;
    }
    case MENU.ADMISSION_CHECKLIST: {
      if (!defaultValues) return null;
      return <MedicalRecords {...dialogProps} onClose={onCloseSave} />;
    }
    case MENU.CRRT_AGREEMENT: {
      if (!defaultValues) return null;
      return <CRRT {...dialogProps} onClose={onCloseSave} />;
    }
    case MENU.CENTRALVENOUS: {
      if (!defaultValues) return null;
      return <CentralVenous {...dialogProps} onClose={onCloseSave} />;
    }
    case MENU.INTUBATIONAGREEMENT: {
      if (!defaultValues) return null;
      return <IntubationAgreement {...dialogProps} onClose={onCloseSave} />;
    }
    case MENU.SUPPRESSOR: {
      if (!defaultValues) return null;
      return <Suppressor {...dialogProps} onClose={onCloseSave} />;
    }
    case MENU.DRUGCALCULATOR: {
      if (!defaultValues) return null;
      return <DrugCalculatior {...dialogProps} onClose={onCloseReadOnly} />;
    }
    case MENU.CORE_NURSING_SKILL_VIDEO: {
      if (!defaultValues) return null;
      return <CoreNursingSkillVideo {...dialogProps} onClose={onCloseSave} />;
    }
    default:
      return null;
  }
};

export default DisplaySurvey;
