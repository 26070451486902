import { Fragment } from 'react';
import {
  Grid,
  Box,
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
  RadioGroup,
  Radio,
  useTheme,
} from '@mui/material';
import SectionTitle from '../../components/SectionTitle';
import { IFormValues, IFormWatch } from 'routes/Main/type';
import useTableForm from '../../hooks/useTableForm';
import MuiRadioGroupSub from './components/MuiRadioGroupSub';

interface Props extends IFormValues, IFormWatch {
  disabled?: boolean;
}

const NDIContents = (props: Props) => {
  const { palette } = useTheme();

  const { radioGroup, sumValues } = useTableForm(props);
  const { disabled, watch, setValue, getValues } = props;

  const answers = [
    {
      id: 'ndi01',
      title: 'Section 1 - Pain Intensity',
      desc: [
        'I have no pain at the moment.',
        'The pain is very mild at the moment.',
        'The pain is moderate at the moment.',
        'The pain is fairly severe at the moment.',
        'The pain is very severe at the moment.',
        'The pain is the worst imaginable at the moment.',
      ],
    },
    {
      id: 'ndi02',
      title: 'Section 2 - Personal Care (Washing, Dressing, etc.)',
      desc: [
        'I can look after myself normally without causing extra pain.',
        'I can look after myself normally, but it causes extra pain.',
        'It is painful to look after myself and I am slow and careful.',
        'I need some help but can manage most of my personal care.',
        'I need help every day in most aspects of self care.',
        'I do not get dressed, I wash with difficulty and stay in bed.',
      ],
    },
    {
      id: 'ndi03',
      title: 'Section 3 - Lifting',
      desc: [
        'I can lift heavy weights without extra pain.',
        'I can lift heavy weights, but it gives extra pain.',
        'Pain prevents me lifting heavy weights off the floor, but I can manage if they are conveniently placed, for example on a table.',
        'Pain prevents me from lifting heavy weights, but I can manage light to medium weights if they are conveniently positioned.',
        'I can only lift very light weights.',
        'I cannot lift or carry anything.',
      ],
    },
    {
      id: 'ndi04',
      title: 'Section 4 - Reading',
      desc: [
        'I can read as much as I want to with no pain in my neck.',
        'I can read as much as I want to with slight pain in my neck.',
        'I can read as much as I want with moderate pain in my neck.',
        'I can’t  read as much as I want because of moderate pain in my neck.',
        'I can hardly read at all because of severe pain in my neck.',
        'I cannot read at all.',
      ],
    },
    {
      id: 'ndi05',
      title: 'Section 5 - Headaches',
      desc: [
        'I have no headaches at all.',
        'I have slight headaches which come infrequently.',
        'I have moderate headaches which come infrequently.',
        'I have moderate headaches which come frequently.',
        'I have severe headaches which come frequently.',
        'I have headaches almost all the time.',
      ],
    },
    {
      id: 'ndi06',
      title: 'Section 6 - Concentration',
      desc: [
        'I can concentrate fully when I want to with no difficulty.',
        'I can concentrate fully when I want to with slight difficulty.',
        'I have a fair degree of difficulty in concentrating when I want to.',
        'I have a lot of difficulty in concentrating when I want to.',
        'I have a great deal of difficulty in concentrating when I want to.',
        'I cannot concentrate at all.',
      ],
    },
    {
      id: 'ndi07',
      title: 'Section 7 - Work',
      desc: [
        'I can do as much work as I want to.',
        'I can only do my usual work, but no more.',
        'I can do most of my usual work, but no more.',
        'I cannot do my usual work.',
        'I can hardly do any work at all.',
        'I can’t  do any work at all.',
      ],
    },
    {
      id: 'ndi08',
      title: 'Section 8 - Driving',
      desc: [
        'I can drive my car without any neck pain.',
        'I can drive my car as long as I want with slight pain in my neck.',
        'I can drive my car as long as I want with moderate pain in my neck.',
        'I can’t  drive my car as long as I want because of moderate pain in my neck.',
        'I can hardly drive at all because of severe pain in my neck.',
        'I can’t  drive my car at all.',
      ],
    },
    {
      id: 'ndi09',
      title: 'Section 9 - Sleeping',
      desc: [
        'I have no trouble sleeping.',
        'My sleep is slightly disturbed (less than 1 hour sleepless).',
        'My sleep is mildly disturbed (1-2 hours sleepless).',
        'My sleep is moderately disturbed (2-3 hours sleepless).',
        'My sleep is greatly disturbed (3-5 hours sleepless).',
        'My sleep is completely disturbed (5-7 hours sleepless).',
      ],
    },
    {
      id: 'ndi10',
      title: 'Section 10 - Reaction',
      desc: [
        'I am able to engage in all my recreational activities with no neck pain at all.',
        'I am able to engage in all my recreational activities, with some pain in my neck.',
        'I am able to engage in most, but not all of my usual recreational activities because of pain in my neck.',
        'I am able to engage in a few of my usual recreational activities because of pain in my neck.',
        'I can hardly do any recreational activities because of pain in my neck.',
        'I can’t  do any recreational activities at all.',
      ],
    },
  ];

  const watchSumValues = () => {
    const values = answers.map(({ id }) => {
      if (props.watch(`${id}`) === undefined) {
        return 0;
      } else {
        return Number(props.watch(`${id}`));
      }
    });
    return sumValues(values);
  };

  // console.log('기본값', Number(getValues('ndi10')));

  return (
    <Fragment>
      <Typography sx={{ fontSize: '14px', margin: '40px 0 -10px 25px' }}>
        This questionnaire has been designed to give us information as to how
        your neck pain has affected your ability to manage in everyday life.
        Please answer every section and mark in each
        <br />
        section only the one box that applies to you. We realise you may
        consider that two or more statements in any one section relate to you,
        but please just mark the box that most closely
        <br />
        describes your problem.
      </Typography>
      {answers.map(
        (answer: { id: string; title: string; desc: string[] }, idx) => (
          <>
            <SectionTitle title={answer.title} />
            <Grid item xs={12}>
              <RadioGroup
                name={answer.title}
                defaultValue={Number(getValues(`answer.id`))}
              >
                <Table aria-label="simple table">
                  <TableBody>
                    {answer.desc.map((v, i) => {
                      return (
                        <TableRow>
                          <TableCell
                            sx={{
                              width: '80%',
                            }}
                          >
                            {v}
                          </TableCell>
                          <TableCell sx={{ width: '10%' }}>{`${i}`}</TableCell>
                          <TableCell>
                            <MuiRadioGroupSub
                              disabled={disabled}
                              name={`${answer.title}`}
                              values={[i]}
                              value={Number(getValues(`${answer.id}`))}
                              defaultValue={Number(getValues(`${answer.id}`))}
                              onChange={() => {
                                setValue(`${answer.id}`, i);
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </RadioGroup>
            </Grid>
          </>
        )
      )}

      <Grid item xs={12}>
        <Box display={'flex'} flexDirection={'column'} alignItems={'flex-end'}>
          <Typography
            gutterBottom
            minWidth={115}
            fontWeight={700}
            variant="subtitle1"
          >
            Total : {watchSumValues()} / 50
          </Typography>
          <Typography
            minWidth={115}
            variant="caption"
            sx={{ color: `${palette.primary.main}`, marginTop: '30px' }}
          >
            <Typography variant="inherit">
              <Box component={'strong'} mr={0.5}>
                0-4
              </Box>
              no disability
            </Typography>
            <Typography variant="inherit">
              <Box component={'strong'} mr={0.5}>
                5-14
              </Box>
              mild disability
            </Typography>
            <Typography variant="inherit">
              <Box component={'strong'} mr={0.5}>
                15-24
              </Box>
              moderate disability
            </Typography>
            <Typography variant="inherit">
              <Box component={'strong'} mr={0.5}>
                25-34
              </Box>
              severe disability
            </Typography>
            <Typography variant="inherit">
              <Box component={'strong'} mr={0.5}>
                35-50
              </Box>
              complete disability
            </Typography>
          </Typography>
        </Box>
      </Grid>
    </Fragment>
  );
};

export default NDIContents;
