import { Fragment, useState } from 'react';
import { Grid } from '@mui/material';

import Form from 'components/Form';
import MuiTable from 'components/MuiTable';
import { IFormRegister } from 'routes/Main/type';

import RowContainer from '../components/RowContainer';
import SectionTitle from '../components/SectionTitle';

interface Props extends IFormRegister {
  disabled?: boolean;
}

const CheckReservations = (props: Props) => {
  const { disabled, register } = props;

  const columns = [
    { fieldId: 'name', label: 'Inspection Name' },
    { fieldId: 'destination', label: 'Place' },
    { fieldId: 'warning', label: 'Precautions' },
    { fieldId: 'call_number', label: 'Phone Number' },
    { fieldId: 'date', label: 'Reservation Date' },
  ];

  const rows = Array.from({ length: 2 }, (_, i) => {
    const prefix = `check_reservations.${i}`;
    return {
      id: i,
      name: (
        <Form.MuiTextField
          disabled={disabled}
          {...register(`${prefix}.name`)}
        />
      ),
      destination: (
        <Form.MuiTextField
          disabled={disabled}
          {...register(`${prefix}.destination`)}
        />
      ),
      warning: (
        <Form.MuiTextField
          disabled={disabled}
          {...register(`${prefix}.warning`)}
        />
      ),
      call_number: (
        <Form.MuiTextField
          type="tel"
          disabled={disabled}
          {...register(`${prefix}.call_number`)}
        />
      ),
      date: (
        <Form.MuiTextField
          type="date"
          InputLabelProps={{ shrink: true }}
          required={false}
          disabled={disabled}
          {...register(`${prefix}.date`)}
        />
      ),
    };
  });

  return (
    <Fragment>
      <SectionTitle title="Examination Reservation" />

      <RowContainer xs={12}>
        <Grid item xs={12}>
          <MuiTable columns={columns} rows={rows} />
        </Grid>
      </RowContainer>
    </Fragment>
  );
};

export default CheckReservations;
