import { Box, Typography } from '@mui/material';

import SectionTitle from './SectionTitle';

interface Props {
  disabled?: boolean;
}

const contents = [
  'The patient requires endotracheal intubation for securing and maintaining the airway in emergency situations such as cardiac arrest, respiratory failure, upper airway obstruction, etc,\nto prevent aspiration of gastric contents, facilitate the removal of airway secretions, and administer mechanical ventilation if indicated: as specified in the relevant conditions.',
  "As needed, after administering sedatives or muscle relaxants, the patient is placed in a supine position on the bed. The laryngoscope is then inserted into the mouth, lifting the epiglottis \nto insert a plastic tube through the vocal cords into the patient's airway.",
];

const ResultPurpose = (props: Props) => {
  const { disabled } = props;

  return (
    <>
      <SectionTitle title="1. Necessity of the procedure" />
      <Box sx={{ width: '98%', margin: '48px auto 24px auto' }}>
        {contents.map((v, i) => (
          <Typography
            sx={{
              fontWeight: '400',
              fontSize: '14px',
              lineHeight: '24px',
              whiteSpace: 'pre-wrap',
              marginBottom: '20px',
            }}
          >
            {v}
          </Typography>
        ))}
      </Box>
    </>
  );
};

export default ResultPurpose;
