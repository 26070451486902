import { Box, Typography, useTheme } from '@mui/material';

import SectionTitle from './components/SectionTitle';

interface Props {
  disabled?: boolean;
}

const contents = [
  'I acknowledge that the doctor has explained the following:',
  'My medical condition and the proposed procedure, including additional treatment if the doctor finds something unexpected. I understand the risks, including those specific to me.',
  '(The anesthetic required for this procedure; I understand the risks, including those specific to me.)',
  'Other relevant procedure/treatment options and their associated risks.',
  'My prognosis and the risks of not having the procedure.',
  'That no guarantee has been made that the procedure will improve my condition even though it has been cared out with due professional care.',
  '(The procedure may include blood transfusion.)',
  'Tissues and blood may be removed and could be used for diagnosis or management of my condition, stored, and disposed of sensitively by the hospital.',
  'If immediate life-threatening events occur during the procedure, they will be treated based on my discussions with the doctor or my Acute Resuscitation Plan.',
  'A doctor other than the Consultant may perform the procedure. I understand that this could be a doctor undergoing further training.',
  'I was able to ask questions and raise concerns with the doctor regarding my condition, the proposed procedure and its risks, and my treatment options. My questions and concerns \n     have been discussed and answered to my satisfaction.',
  'I understand that I have the right to change my mind at any time, including after I have signed this form but preferably following a discussion with my doctor.',
  'I understand that image/s or video footage may be recorded as part of and during my procedure, and that these image/s or video/s will assist the doctor in providing appropriate \n     treatment.',
];

const GeneralPateintConsentForm = (props: Props) => {
  const { disabled } = props;
  const { palette } = useTheme();

  return (
    <>
      <SectionTitle title="General Patient Consent Form" />
      <Box
        sx={{
          width: '98%',
          margin: '48px auto 10px auto',
        }}
      >
        <Typography
          sx={{
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '24px',
            whiteSpace: 'pre-wrap',
          }}
        >
          I certify that I have read and understood this treatment agreement,
          and that all the fields were filled in before I signed it.
        </Typography>
      </Box>

      <Box
        sx={{
          width: '98%',
          margin: '0px auto 10px auto',
          border: '1px solid lightgray',
          padding: '10px',
        }}
      >
        {contents.map((v, i) => {
          if (i === 0) {
            return (
              <Typography
                sx={{
                  fontWeight: '400',
                  fontSize: '14px',
                  lineHeight: '24px',
                  whiteSpace: 'pre-wrap',
                }}
              >
                {v}
              </Typography>
            );
          }
          return (
            <Typography
              sx={{
                fontWeight: '400',
                fontSize: '14px',
                lineHeight: '24px',
                whiteSpace: 'pre-wrap',
              }}
            >
              • {v}
            </Typography>
          );
        })}
      </Box>

      <Box
        sx={{
          width: '98%',
          margin: '0px auto 0px auto',
        }}
      >
        <Typography
          sx={{
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '24px',
            whiteSpace: 'pre-wrap',
          }}
        >
          Based on the foregoing statements,
        </Typography>
        <br />

        <Typography
          sx={{
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '24px',
            whiteSpace: 'pre-wrap',
            color: `${palette.primary.main}`,
          }}
        >
          I have carefully read and understood all of the information above, and
          I am fully aware of what I am signing. I understand that I may ask my
          practitioner for a more detailed
          <br />
          explanation. I hereby give my Permission and consent to treatment.
        </Typography>
      </Box>
    </>
  );
};

export default GeneralPateintConsentForm;
