import { Box, Typography, useTheme } from '@mui/material';

import SectionTitle from '../../components/SectionTitle';

interface Props {
  disabled?: boolean;
}

const contents = [
  'Conscious sedation involves the administration of sedative agent called midazolam through an intravenous catheter. Conscious sedation allows loss of pain and induces amnesia \nwithout causing complete loss of consciousness. In addition to keeping the patient free from pain during the procedure, conscious sedation also relieves stress and anxiety related to \nthe procedure. Midazolam is therefore usually used in cases when patients have high anxiety levels or who are fearful of endoscopic procedures. With some people, sedation causes \nmore difficulty at the time of insertion of the endoscope. Moreover in some cases, sleep is unable to be induced even with large doses of the medication. However, further dosage \nincreasing is not allowed due to the side effects of midazolam. Side effects of the use of midazolam include difficulty breathing, decrease in blood pressure, increase in heart rate, as \nwell as hiccupping and coughing. These symptoms are known to disappear naturally without any special treatments. In rare cases, severe side effects such as respiratory and/or cardiac \narrest could happen, and emergency care would be given at this time. The use of midazolam is contraindicated for those who are elderly, have low blood pressure, respiratory problems, \nheart problems or other medical conditions ',
];

const contents2 = [
  'For those who choose conscious sedation, it is necessary that the patient be accompanied by a friend or family member on the day of the test, since it would take approximately 2 hours or \nmore for the medication to wear off. In addition, the patient should not drive after the procedure.',
  "I have received sufficient information about the nature, purpose, and side effects of midazolam, and give my consent to the use of midiazolam in this procedure. I understand that during \nthe procedure, it is possible for something unexpected to happen which may require another or different procedure be performed on me (the patient). If something unexpected happens \nduring the procedure, I hereby request and authorize my (the patient's) physician to do what is medically necessary and appropriate for me (the patient), including performing another \nprocedure or test.",
  'Please write “ I have heard” in the space provided, If you have heard about the symptoms and treatment, risks and possible complications.',
];

const CautionList = (props: Props) => {
  const { disabled } = props;
  const { palette } = useTheme();

  return (
    <>
      <SectionTitle title="Informed Consent form for conscious sedation" />
      <Box
        sx={{
          width: '98%',
          margin: '48px auto 24px auto',
          border: '1px solid lightgray',
          padding: '10px',
        }}
      >
        {contents.map((v, i) => {
          if (i === 3) {
            return (
              <Typography
                sx={{
                  fontWeight: '500',
                  fontSize: '14px',
                  lineHeight: '24px',
                  color: `${palette.primary.main}`,
                }}
              >
                {v}
                <p />
              </Typography>
            );
          }
          return (
            <Typography
              sx={{ fontWeight: '400', fontSize: '14px', lineHeight: '24px' }}
            >
              {v}
              <p />
            </Typography>
          );
        })}
      </Box>

      <Box sx={{ width: '98%', margin: '0px auto 24px auto' }}>
        {contents2.map((v, i) => {
          if (i === 2) {
            return (
              <Typography
                sx={{
                  fontWeight: '500',
                  fontSize: '14px',
                  lineHeight: '24px',
                  color: `${palette.primary.main}`,
                }}
              >
                {v}
                <p />
              </Typography>
            );
          }
          return (
            <Typography
              sx={{ fontWeight: '400', fontSize: '14px', lineHeight: '24px' }}
            >
              {v}
              <p />
            </Typography>
          );
        })}
      </Box>
    </>
  );
};

export default CautionList;
