import { Grid, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';

import { updateClinicObservationICU } from 'apis/survey';
import useSurvey from 'store/survey/useSurvey';
import useNotification from 'hooks/useNotification';
import MuiDialog from 'components/MuiDialog';
import {
  TClinicalObservationICUDefaultValues,
  SurveyDialogProps,
} from 'routes/Main/Survey/type';

import CommonPatientInfo from '../components/CommonPatientInfo';
import VitalSignGraph from './VitalSignGraph';
import vitalsign from 'store/vitalsign';

const Clinicalobs_ICU = (
  props: SurveyDialogProps<TClinicalObservationICUDefaultValues>
) => {
  const {
    title,
    isOpen,
    disabled,
    defaultValues,
    user_id,
    patientInfo,
    nurseName,
    onClose,
  } = props;

  const { onUpdateIsSave } = useSurvey();
  const { onSuccess, onFail, onResultCode, onRequired } = useNotification();

  const { handleSubmit, watch, getValues, setValue, register } = useForm({
    defaultValues,
  });

  const onSubmit = (data: TClinicalObservationICUDefaultValues) => {
    const {
      vital_sign,
      weight_height,
      intake,
      output,
      position_change,
      restraint,
      ipc,
      vent,
      crrt,
    } = data;

    const request = {
      user_id,
      patient_id: patientInfo.patient_id,
      catholic_clinical_observation: {
        vital_sign: {
          vital00: {
            no00: vital_sign.vital00.no00,
            no01: vital_sign.vital00.no01,
            no02: vital_sign.vital00.no02,
            no03: vital_sign.vital00.no03,
            no04: vital_sign.vital00.no04,
            no05: vital_sign.vital00.no05,
            no06: vital_sign.vital00.no06,
            no07: vital_sign.vital00.no07,
            no08: vital_sign.vital00.no08,
            no09: vital_sign.vital00.no09,
          },
          vital01: {
            no10: vital_sign.vital01.no10,
            no11: vital_sign.vital01.no11,
            no12: vital_sign.vital01.no12,
            no13: vital_sign.vital01.no13,
            no14: vital_sign.vital01.no14,
            no15: vital_sign.vital01.no15,
            no16: vital_sign.vital01.no16,
            no17: vital_sign.vital01.no17,
            no18: vital_sign.vital01.no18,
            no19: vital_sign.vital01.no19,
          },
          vital02: {
            no20: vital_sign.vital02.no20,
            no21: vital_sign.vital02.no21,
            no22: vital_sign.vital02.no22,
            no23: vital_sign.vital02.no23,
            no24: vital_sign.vital02.no24,
            no25: vital_sign.vital02.no25,
            no26: vital_sign.vital02.no26,
            no27: vital_sign.vital02.no27,
            no28: vital_sign.vital02.no28,
            no29: vital_sign.vital02.no29,
          },
          vital03: {
            no30: vital_sign.vital03.no30,
            no31: vital_sign.vital03.no31,
            no32: vital_sign.vital03.no32,
            no33: vital_sign.vital03.no33,
            no34: vital_sign.vital03.no34,
            no35: vital_sign.vital03.no35,
            no36: vital_sign.vital03.no36,
            no37: vital_sign.vital03.no37,
            no38: vital_sign.vital03.no38,
            no39: vital_sign.vital03.no39,
          },
          vital04: {
            no40: vital_sign.vital04.no40,
            no41: vital_sign.vital04.no41,
            no42: vital_sign.vital04.no42,
            no43: vital_sign.vital04.no43,
            no44: vital_sign.vital04.no44,
            no45: vital_sign.vital04.no45,
            no46: vital_sign.vital04.no46,
            no47: vital_sign.vital04.no47,
            no48: vital_sign.vital04.no48,
            no49: vital_sign.vital04.no49,
          },
          vital05: {
            no50: vital_sign.vital05.no50,
            no51: vital_sign.vital05.no51,
            no52: vital_sign.vital05.no52,
            no53: vital_sign.vital05.no53,
            no54: vital_sign.vital05.no54,
            no55: vital_sign.vital05.no55,
            no56: vital_sign.vital05.no56,
            no57: vital_sign.vital05.no57,
            no58: vital_sign.vital05.no58,
            no59: vital_sign.vital05.no59,
          },
          vital06: {
            no60: vital_sign.vital06.no60,
            no61: vital_sign.vital06.no61,
            no62: vital_sign.vital06.no62,
            no63: vital_sign.vital06.no63,
            no64: vital_sign.vital06.no64,
            no65: vital_sign.vital06.no65,
            no66: vital_sign.vital06.no66,
            no67: vital_sign.vital06.no67,
            no68: vital_sign.vital06.no68,
            no69: vital_sign.vital06.no69,
          },
          vital07: {
            no70: vital_sign.vital07.no70,
            no71: vital_sign.vital07.no71,
            no72: vital_sign.vital07.no72,
            no73: vital_sign.vital07.no73,
            no74: vital_sign.vital07.no74,
            no75: vital_sign.vital07.no75,
            no76: vital_sign.vital07.no76,
            no77: vital_sign.vital07.no77,
            no78: vital_sign.vital07.no78,
            no79: vital_sign.vital07.no79,
          },
          vital08: {
            no80: vital_sign.vital08.no80,
            no81: vital_sign.vital08.no81,
            no82: vital_sign.vital08.no82,
            no83: vital_sign.vital08.no83,
            no84: vital_sign.vital08.no84,
            no85: vital_sign.vital08.no85,
            no86: vital_sign.vital08.no86,
            no87: vital_sign.vital08.no87,
            no88: vital_sign.vital08.no88,
            no89: vital_sign.vital08.no89,
          },
          vital09: {
            no90: vital_sign.vital09.no90,
            no91: vital_sign.vital09.no91,
            no92: vital_sign.vital09.no92,
            no93: vital_sign.vital09.no93,
            no94: vital_sign.vital09.no94,
            no95: vital_sign.vital09.no95,
            no96: vital_sign.vital09.no96,
            no97: vital_sign.vital09.no97,
            no98: vital_sign.vital09.no98,
            no99: vital_sign.vital09.no99,
          },
        },
        weight_height: {
          weight_height00: {
            no00: weight_height.weight_height00.no00,
            no01: weight_height.weight_height00.no01,
          },
          weight_height01: {
            no10: weight_height.weight_height01.no10,
            no11: weight_height.weight_height01.no11,
          },
          weight_height02: {
            no20: weight_height.weight_height02.no20,
            no21: weight_height.weight_height02.no21,
          },
          weight_height03: {
            no30: weight_height.weight_height03.no30,
            no31: weight_height.weight_height03.no31,
          },
          weight_height04: {
            no40: weight_height.weight_height04.no40,
            no41: weight_height.weight_height04.no41,
          },
          weight_height05: {
            no50: weight_height.weight_height05.no50,
            no51: weight_height.weight_height05.no51,
          },
          weight_height06: {
            no60: weight_height.weight_height06.no60,
            no61: weight_height.weight_height06.no61,
          },
          weight_height07: {
            no70: weight_height.weight_height07.no70,
            no71: weight_height.weight_height07.no71,
          },
          weight_height08: {
            no80: weight_height.weight_height08.no80,
            no81: weight_height.weight_height08.no81,
          },
          weight_height09: {
            no90: weight_height.weight_height09.no90,
            no91: weight_height.weight_height09.no91,
          },
        },
        intake: {
          intake00: {
            no00: intake.intake00.no00,
            no01: intake.intake00.no01,
            no02: intake.intake00.no02,
            no03: intake.intake00.no03,
          },
          intake01: {
            no10: intake.intake01.no10,
            no11: intake.intake01.no11,
            no12: intake.intake01.no12,
            no13: intake.intake01.no13,
          },
          intake02: {
            no20: intake.intake02.no20,
            no21: intake.intake02.no21,
            no22: intake.intake02.no22,
            no23: intake.intake02.no23,
          },
          intake03: {
            no30: intake.intake03.no30,
            no31: intake.intake03.no31,
            no32: intake.intake03.no32,
            no33: intake.intake03.no33,
          },
          intake04: {
            no40: intake.intake04.no40,
            no41: intake.intake04.no41,
            no42: intake.intake04.no42,
            no43: intake.intake04.no43,
          },
          intake05: {
            no50: intake.intake05.no50,
            no51: intake.intake05.no51,
            no52: intake.intake05.no52,
            no53: intake.intake05.no53,
          },
          intake06: {
            no60: intake.intake06.no60,
            no61: intake.intake06.no61,
            no62: intake.intake06.no62,
            no63: intake.intake06.no63,
          },
          intake07: {
            no70: intake.intake07.no70,
            no71: intake.intake07.no71,
            no72: intake.intake07.no72,
            no73: intake.intake07.no73,
          },
          intake08: {
            no80: intake.intake08.no80,
            no81: intake.intake08.no81,
            no82: intake.intake08.no82,
            no83: intake.intake08.no83,
          },
          intake09: {
            no90: intake.intake09.no90,
            no91: intake.intake09.no91,
            no92: intake.intake09.no92,
            no93: intake.intake09.no93,
          },
        },
        output: {
          output00: {
            no00: output.output00.no00,
            no01: output.output00.no01,
            no02: output.output00.no02,
            no03: output.output00.no03,
            no04: output.output00.no04,
          },
          output01: {
            no10: output.output01.no10,
            no11: output.output01.no11,
            no12: output.output01.no12,
            no13: output.output01.no13,
            no14: output.output01.no14,
          },
          output02: {
            no20: output.output02.no20,
            no21: output.output02.no21,
            no22: output.output02.no22,
            no23: output.output02.no23,
            no24: output.output02.no24,
          },
          output03: {
            no30: output.output03.no30,
            no31: output.output03.no31,
            no32: output.output03.no32,
            no33: output.output03.no33,
            no34: output.output03.no34,
          },
          output04: {
            no40: output.output04.no40,
            no41: output.output04.no41,
            no42: output.output04.no42,
            no43: output.output04.no43,
            no44: output.output04.no44,
          },
          output05: {
            no50: output.output05.no50,
            no51: output.output05.no51,
            no52: output.output05.no52,
            no53: output.output05.no53,
            no54: output.output05.no54,
          },
          output06: {
            no60: output.output06.no60,
            no61: output.output06.no61,
            no62: output.output06.no62,
            no63: output.output06.no63,
            no64: output.output06.no64,
          },
          output07: {
            no70: output.output07.no70,
            no71: output.output07.no71,
            no72: output.output07.no72,
            no73: output.output07.no73,
            no74: output.output07.no74,
          },
          output08: {
            no80: output.output08.no80,
            no81: output.output08.no81,
            no82: output.output08.no82,
            no83: output.output08.no83,
            no84: output.output08.no84,
          },
          output09: {
            no90: output.output09.no90,
            no91: output.output09.no91,
            no92: output.output09.no92,
            no93: output.output09.no93,
            no94: output.output09.no94,
          },
        },
        position_change: {
          position_change00: {
            no00: position_change.position_change00.no00,
            no01: position_change.position_change00.no01,
            no02: position_change.position_change00.no02,
            no03: position_change.position_change00.no03,
          },
          position_change01: {
            no10: position_change.position_change01.no10,
            no11: position_change.position_change01.no11,
            no12: position_change.position_change01.no12,
            no13: position_change.position_change01.no13,
          },
          position_change02: {
            no20: position_change.position_change02.no20,
            no21: position_change.position_change02.no21,
            no22: position_change.position_change02.no22,
            no23: position_change.position_change02.no23,
          },
          position_change03: {
            no30: position_change.position_change03.no30,
            no31: position_change.position_change03.no31,
            no32: position_change.position_change03.no32,
            no33: position_change.position_change03.no33,
          },
          position_change04: {
            no40: position_change.position_change04.no40,
            no41: position_change.position_change04.no41,
            no42: position_change.position_change04.no42,
            no43: position_change.position_change04.no43,
          },
          position_change05: {
            no50: position_change.position_change05.no50,
            no51: position_change.position_change05.no51,
            no52: position_change.position_change05.no52,
            no53: position_change.position_change05.no53,
          },
          position_change06: {
            no60: position_change.position_change06.no60,
            no61: position_change.position_change06.no61,
            no62: position_change.position_change06.no62,
            no63: position_change.position_change06.no63,
          },
          position_change07: {
            no70: position_change.position_change07.no70,
            no71: position_change.position_change07.no71,
            no72: position_change.position_change07.no72,
            no73: position_change.position_change07.no73,
          },
          position_change08: {
            no80: position_change.position_change08.no80,
            no81: position_change.position_change08.no81,
            no82: position_change.position_change08.no82,
            no83: position_change.position_change08.no83,
          },
          position_change09: {
            no90: position_change.position_change09.no90,
            no91: position_change.position_change09.no91,
            no92: position_change.position_change09.no92,
            no93: position_change.position_change09.no93,
          },
        },
        restraint: {
          restraint00: {
            no00: restraint.restraint00.no00,
            no01: restraint.restraint00.no01,
            no02: restraint.restraint00.no02,
            no03: restraint.restraint00.no03,
            no04: restraint.restraint00.no04,
            no05: restraint.restraint00.no05,
            no06: restraint.restraint00.no06,
            no07: restraint.restraint00.no07,
            no08: restraint.restraint00.no08,
          },
          restraint01: {
            no10: restraint.restraint01.no10,
            no11: restraint.restraint01.no11,
            no12: restraint.restraint01.no12,
            no13: restraint.restraint01.no13,
            no14: restraint.restraint01.no14,
            no15: restraint.restraint01.no15,
            no16: restraint.restraint01.no16,
            no17: restraint.restraint01.no17,
            no18: restraint.restraint01.no18,
          },
          restraint02: {
            no20: restraint.restraint02.no20,
            no21: restraint.restraint02.no21,
            no22: restraint.restraint02.no22,
            no23: restraint.restraint02.no23,
            no24: restraint.restraint02.no24,
            no25: restraint.restraint02.no25,
            no26: restraint.restraint02.no26,
            no27: restraint.restraint02.no27,
            no28: restraint.restraint02.no28,
          },
          restraint03: {
            no30: restraint.restraint03.no30,
            no31: restraint.restraint03.no31,
            no32: restraint.restraint03.no32,
            no33: restraint.restraint03.no33,
            no34: restraint.restraint03.no34,
            no35: restraint.restraint03.no35,
            no36: restraint.restraint03.no36,
            no37: restraint.restraint03.no37,
            no38: restraint.restraint03.no38,
          },
          restraint04: {
            no40: restraint.restraint04.no40,
            no41: restraint.restraint04.no41,
            no42: restraint.restraint04.no42,
            no43: restraint.restraint04.no43,
            no44: restraint.restraint04.no44,
            no45: restraint.restraint04.no45,
            no46: restraint.restraint04.no46,
            no47: restraint.restraint04.no47,
            no48: restraint.restraint04.no48,
          },
          restraint05: {
            no50: restraint.restraint05.no50,
            no51: restraint.restraint05.no51,
            no52: restraint.restraint05.no52,
            no53: restraint.restraint05.no53,
            no54: restraint.restraint05.no54,
            no55: restraint.restraint05.no55,
            no56: restraint.restraint05.no56,
            no57: restraint.restraint05.no57,
            no58: restraint.restraint05.no58,
          },
          restraint06: {
            no60: restraint.restraint06.no60,
            no61: restraint.restraint06.no61,
            no62: restraint.restraint06.no62,
            no63: restraint.restraint06.no63,
            no64: restraint.restraint06.no64,
            no65: restraint.restraint06.no65,
            no66: restraint.restraint06.no66,
            no67: restraint.restraint06.no67,
            no68: restraint.restraint06.no68,
          },
          restraint07: {
            no70: restraint.restraint07.no70,
            no71: restraint.restraint07.no71,
            no72: restraint.restraint07.no72,
            no73: restraint.restraint07.no73,
            no74: restraint.restraint07.no74,
            no75: restraint.restraint07.no75,
            no76: restraint.restraint07.no76,
            no77: restraint.restraint07.no77,
            no78: restraint.restraint07.no78,
          },
          restraint08: {
            no80: restraint.restraint08.no80,
            no81: restraint.restraint08.no81,
            no82: restraint.restraint08.no82,
            no83: restraint.restraint08.no83,
            no84: restraint.restraint08.no84,
            no85: restraint.restraint08.no85,
            no86: restraint.restraint08.no86,
            no87: restraint.restraint08.no87,
            no88: restraint.restraint08.no88,
          },
          restraint09: {
            no90: restraint.restraint09.no90,
            no91: restraint.restraint09.no91,
            no92: restraint.restraint09.no92,
            no93: restraint.restraint09.no93,
            no94: restraint.restraint09.no94,
            no95: restraint.restraint09.no95,
            no96: restraint.restraint09.no96,
            no97: restraint.restraint09.no97,
            no98: restraint.restraint09.no98,
          },
        },
        ipc: {
          ipc00: ipc.ipc00,
          ipc01: ipc.ipc01,
          ipc02: ipc.ipc02,
          ipc03: ipc.ipc03,
          ipc04: ipc.ipc04,
          ipc05: ipc.ipc05,
          ipc06: ipc.ipc06,
          ipc07: ipc.ipc07,
          ipc08: ipc.ipc08,
          ipc09: ipc.ipc09,
        },
        vent: {
          vent00: {
            no00: vent.vent00.no00,
            no01: vent.vent00.no01,
            no02: vent.vent00.no02,
            no03: vent.vent00.no03,
            no04: vent.vent00.no04,
            no05: vent.vent00.no05,
            no06: vent.vent00.no06,
            no07: vent.vent00.no07,
            no08: vent.vent00.no08,
            no09: vent.vent00.no09,
            no010: vent.vent00.no010,
            no011: vent.vent00.no011,
          },
          vent01: {
            no10: vent.vent01.no10,
            no11: vent.vent01.no11,
            no12: vent.vent01.no12,
            no13: vent.vent01.no13,
            no14: vent.vent01.no14,
            no15: vent.vent01.no15,
            no16: vent.vent01.no16,
            no17: vent.vent01.no17,
            no18: vent.vent01.no18,
            no19: vent.vent01.no19,
            no110: vent.vent01.no110,
            no111: vent.vent01.no111,
          },
          vent02: {
            no20: vent.vent02.no20,
            no21: vent.vent02.no21,
            no22: vent.vent02.no22,
            no23: vent.vent02.no23,
            no24: vent.vent02.no24,
            no25: vent.vent02.no25,
            no26: vent.vent02.no26,
            no27: vent.vent02.no27,
            no28: vent.vent02.no28,
            no29: vent.vent02.no29,
            no210: vent.vent02.no210,
            no211: vent.vent02.no211,
          },
          vent03: {
            no30: vent.vent03.no30,
            no31: vent.vent03.no31,
            no32: vent.vent03.no32,
            no33: vent.vent03.no33,
            no34: vent.vent03.no34,
            no35: vent.vent03.no35,
            no36: vent.vent03.no36,
            no37: vent.vent03.no37,
            no38: vent.vent03.no38,
            no39: vent.vent03.no39,
            no310: vent.vent03.no310,
            no311: vent.vent03.no311,
          },
          vent04: {
            no40: vent.vent04.no40,
            no41: vent.vent04.no41,
            no42: vent.vent04.no42,
            no43: vent.vent04.no43,
            no44: vent.vent04.no44,
            no45: vent.vent04.no45,
            no46: vent.vent04.no46,
            no47: vent.vent04.no47,
            no48: vent.vent04.no48,
            no49: vent.vent04.no49,
            no410: vent.vent04.no410,
            no411: vent.vent04.no411,
          },
          vent05: {
            no50: vent.vent05.no50,
            no51: vent.vent05.no51,
            no52: vent.vent05.no52,
            no53: vent.vent05.no53,
            no54: vent.vent05.no54,
            no55: vent.vent05.no55,
            no56: vent.vent05.no56,
            no57: vent.vent05.no57,
            no58: vent.vent05.no58,
            no59: vent.vent05.no59,
            no510: vent.vent05.no510,
            no511: vent.vent05.no511,
          },
          vent06: {
            no60: vent.vent06.no60,
            no61: vent.vent06.no61,
            no62: vent.vent06.no62,
            no63: vent.vent06.no63,
            no64: vent.vent06.no64,
            no65: vent.vent06.no65,
            no66: vent.vent06.no66,
            no67: vent.vent06.no67,
            no68: vent.vent06.no68,
            no69: vent.vent06.no69,
            no610: vent.vent06.no610,
            no611: vent.vent06.no611,
          },
          vent07: {
            no70: vent.vent07.no70,
            no71: vent.vent07.no71,
            no72: vent.vent07.no72,
            no73: vent.vent07.no73,
            no74: vent.vent07.no74,
            no75: vent.vent07.no75,
            no76: vent.vent07.no76,
            no77: vent.vent07.no77,
            no78: vent.vent07.no78,
            no79: vent.vent07.no79,
            no710: vent.vent07.no710,
            no711: vent.vent07.no711,
          },
          vent08: {
            no80: vent.vent08.no80,
            no81: vent.vent08.no81,
            no82: vent.vent08.no82,
            no83: vent.vent08.no83,
            no84: vent.vent08.no84,
            no85: vent.vent08.no85,
            no86: vent.vent08.no86,
            no87: vent.vent08.no87,
            no88: vent.vent08.no88,
            no89: vent.vent08.no89,
            no810: vent.vent08.no810,
            no811: vent.vent08.no811,
          },
          vent09: {
            no90: vent.vent09.no90,
            no91: vent.vent09.no91,
            no92: vent.vent09.no92,
            no93: vent.vent09.no93,
            no94: vent.vent09.no94,
            no95: vent.vent09.no95,
            no96: vent.vent09.no96,
            no97: vent.vent09.no97,
            no98: vent.vent09.no98,
            no99: vent.vent09.no99,
            no910: vent.vent09.no910,
            no911: vent.vent09.no911,
          },
        },
        crrt: {
          crrt00: {
            no00: crrt.crrt00.no00,
            no01: crrt.crrt00.no01,
            no02: crrt.crrt00.no02,
            no03: crrt.crrt00.no03,
            no04: crrt.crrt00.no04,
            no05: crrt.crrt00.no05,
            no06: crrt.crrt00.no06,
            no07: crrt.crrt00.no07,
            no08: crrt.crrt00.no08,
            no09: crrt.crrt00.no09,
            no010: crrt.crrt00.no010,
            no011: crrt.crrt00.no011,
            no012: crrt.crrt00.no012,
            no013: crrt.crrt00.no013,
            no014: crrt.crrt00.no014,
          },
          crrt01: {
            no10: crrt.crrt01.no10,
            no11: crrt.crrt01.no11,
            no12: crrt.crrt01.no12,
            no13: crrt.crrt01.no13,
            no14: crrt.crrt01.no14,
            no15: crrt.crrt01.no15,
            no16: crrt.crrt01.no16,
            no17: crrt.crrt01.no17,
            no18: crrt.crrt01.no18,
            no19: crrt.crrt01.no19,
            no110: crrt.crrt01.no110,
            no111: crrt.crrt01.no111,
            no112: crrt.crrt01.no112,
            no113: crrt.crrt01.no113,
            no114: crrt.crrt01.no114,
          },
          crrt02: {
            no20: crrt.crrt02.no20,
            no21: crrt.crrt02.no21,
            no22: crrt.crrt02.no22,
            no23: crrt.crrt02.no23,
            no24: crrt.crrt02.no24,
            no25: crrt.crrt02.no25,
            no26: crrt.crrt02.no26,
            no27: crrt.crrt02.no27,
            no28: crrt.crrt02.no28,
            no29: crrt.crrt02.no29,
            no210: crrt.crrt02.no210,
            no211: crrt.crrt02.no211,
            no212: crrt.crrt02.no212,
            no213: crrt.crrt02.no213,
            no214: crrt.crrt02.no214,
          },
          crrt03: {
            no30: crrt.crrt00.no00,
            no31: crrt.crrt03.no31,
            no32: crrt.crrt03.no32,
            no33: crrt.crrt03.no33,
            no34: crrt.crrt03.no34,
            no35: crrt.crrt03.no35,
            no36: crrt.crrt03.no36,
            no37: crrt.crrt03.no37,
            no38: crrt.crrt03.no38,
            no39: crrt.crrt03.no39,
            no310: crrt.crrt03.no310,
            no311: crrt.crrt03.no311,
            no312: crrt.crrt03.no312,
            no313: crrt.crrt03.no313,
            no314: crrt.crrt03.no314,
          },
          crrt04: {
            no40: crrt.crrt04.no40,
            no41: crrt.crrt04.no41,
            no42: crrt.crrt04.no42,
            no43: crrt.crrt04.no43,
            no44: crrt.crrt04.no44,
            no45: crrt.crrt04.no45,
            no46: crrt.crrt04.no46,
            no47: crrt.crrt04.no47,
            no48: crrt.crrt04.no48,
            no49: crrt.crrt04.no49,
            no410: crrt.crrt04.no410,
            no411: crrt.crrt04.no411,
            no412: crrt.crrt04.no412,
            no413: crrt.crrt04.no413,
            no414: crrt.crrt04.no414,
          },
          crrt05: {
            no50: crrt.crrt05.no50,
            no51: crrt.crrt05.no51,
            no52: crrt.crrt05.no52,
            no53: crrt.crrt05.no53,
            no54: crrt.crrt05.no54,
            no55: crrt.crrt05.no55,
            no56: crrt.crrt05.no56,
            no57: crrt.crrt05.no57,
            no58: crrt.crrt05.no58,
            no59: crrt.crrt05.no59,
            no510: crrt.crrt05.no510,
            no511: crrt.crrt05.no511,
            no512: crrt.crrt05.no512,
            no513: crrt.crrt05.no513,
            no514: crrt.crrt05.no514,
          },
          crrt06: {
            no60: crrt.crrt06.no60,
            no61: crrt.crrt06.no61,
            no62: crrt.crrt06.no62,
            no63: crrt.crrt06.no63,
            no64: crrt.crrt06.no64,
            no65: crrt.crrt06.no65,
            no66: crrt.crrt06.no66,
            no67: crrt.crrt06.no67,
            no68: crrt.crrt06.no68,
            no69: crrt.crrt06.no69,
            no610: crrt.crrt06.no610,
            no611: crrt.crrt06.no611,
            no612: crrt.crrt06.no612,
            no613: crrt.crrt06.no613,
            no614: crrt.crrt06.no614,
          },
          crrt07: {
            no70: crrt.crrt07.no70,
            no71: crrt.crrt07.no71,
            no72: crrt.crrt07.no72,
            no73: crrt.crrt07.no73,
            no74: crrt.crrt07.no74,
            no75: crrt.crrt07.no75,
            no76: crrt.crrt07.no76,
            no77: crrt.crrt07.no77,
            no78: crrt.crrt07.no78,
            no79: crrt.crrt07.no79,
            no710: crrt.crrt07.no710,
            no711: crrt.crrt07.no711,
            no712: crrt.crrt07.no712,
            no713: crrt.crrt07.no713,
            no714: crrt.crrt07.no714,
          },
          crrt08: {
            no80: crrt.crrt08.no80,
            no81: crrt.crrt08.no81,
            no82: crrt.crrt08.no82,
            no83: crrt.crrt08.no83,
            no84: crrt.crrt08.no84,
            no85: crrt.crrt08.no85,
            no86: crrt.crrt08.no86,
            no87: crrt.crrt08.no87,
            no88: crrt.crrt08.no88,
            no89: crrt.crrt08.no89,
            no810: crrt.crrt08.no810,
            no811: crrt.crrt08.no811,
            no812: crrt.crrt08.no812,
            no813: crrt.crrt08.no813,
            no814: crrt.crrt08.no814,
          },
          crrt09: {
            no90: crrt.crrt09.no90,
            no91: crrt.crrt09.no91,
            no92: crrt.crrt09.no92,
            no93: crrt.crrt09.no93,
            no94: crrt.crrt09.no94,
            no95: crrt.crrt09.no95,
            no96: crrt.crrt09.no96,
            no97: crrt.crrt09.no97,
            no98: crrt.crrt09.no98,
            no99: crrt.crrt09.no99,
            no910: crrt.crrt09.no910,
            no911: crrt.crrt09.no911,
            no912: crrt.crrt09.no912,
            no913: crrt.crrt09.no913,
            no914: crrt.crrt09.no914,
          },
        },
      },
    };

    updateClinicObservationICU(request)
      .then(({ data: { rc } }) => {
        if (rc !== 1) return onResultCode(rc);

        onUpdateIsSave(true);
        onSuccess('Saved successfully');
      })
      .catch(e => onFail('Failed to save', e));
  };

  const formProps = {
    disabled,
    watch,
    getValues,
    setValue,
    onSuccess,
    onRequired,
    register,
  };

  return (
    <MuiDialog.SurveyForm
      title={title}
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={disabled ? undefined : handleSubmit(onSubmit)}
      update_at={defaultValues?.update_at}
    >
      <Grid
        container
        wrap="wrap"
        rowSpacing={5}
        columnSpacing={3}
        sx={{ py: 5, px: 1 }}
      >
        <Typography
          sx={{
            margin: '40px auto 0px auto',
            fontWeight: '700',
            fontSize: '16px',
            textAlign: 'center',
          }}
        >
          Clinical Observation Record (ICU)
        </Typography>
        <CommonPatientInfo patientInfo={patientInfo} nurseName={nurseName} />
        <VitalSignGraph {...formProps} />
      </Grid>
    </MuiDialog.SurveyForm>
  );
};

export default Clinicalobs_ICU;
