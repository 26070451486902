import { useEffect, useState } from 'react';

import Form from 'components/Form';
import { IFormRegister, IFormValues, IFormWatch } from 'routes/Main/type';

import { AccessTime } from '@mui/icons-material';
import { MobileTimePicker } from '@mui/x-date-pickers';

import MuiTextField from 'components/Form/MuiTextField';

import RowContainer from '../../components/RowContainer';
import RowContent from '../../components/RowContent';
import SectionTitle from '../../components/SectionTitle';

import RowContentDialysis from './components/RowContent';

interface Props extends IFormRegister, IFormValues, IFormWatch {
  disabled?: boolean;
}

const DialysisInfo = (props: Props) => {
  const { disabled, register, getValues, setValue, watch } = props;

  const contents = [
    {
      label: 'Dialysis day',
      element: (
        <Form.MuiTextField
          required={false}
          type="date"
          disabled={disabled}
          {...register('date')}
          sx={{ marginRight: '10px' }}
        />
      ),
    },
    {
      label: 'Dialysis Time',
      element: (
        <MobileTimePicker
          disabled={disabled}
          value={watch('time') || null}
          onChange={v => setValue('time', v)}
          renderInput={params => (
            <MuiTextField
              {...params}
              required={false}
              placeholder="00:00"
              InputProps={{ endAdornment: <AccessTime /> }}
            />
          )}
        />
      ),
    },
    {
      label: 'Dialyzer',
      element: (
        <Form.MuiTextField
          {...register('dialyzer')}
          required={false}
          disabled={disabled}
        />
      ),
    },
    {
      label: 'Dialysis Machine',
      element: (
        <Form.MuiTextField
          {...register('dialysis_machine')}
          required={false}
          disabled={disabled}
        />
      ),
    },
    {
      label: 'Dialysate Solution',
      element: (
        <RowContainer xs={12}>
          <RowContentDialysis
            title={'Dialysate Solution'}
            titleRatio={1}
            childrenRatio={11}
          >
            <Form.MuiTextField
              {...register('dialysate')}
              required={false}
              disabled={disabled}
            />
          </RowContentDialysis>
        </RowContainer>
      ),
    },
    {
      label: 'Visiting route',
      element: (
        <RowContainer xs={12}>
          <RowContentDialysis
            title={'Visiting route'}
            titleRatio={1}
            childrenRatio={11}
          >
            <Form.MuiRadioGroup
              disabled={disabled}
              i18nKey="DIALYSIS.DIALYSIS_INFO.ROUTE"
              values={[1, 2, 3, 0]}
              defaultValue={getValues('visiting_route')}
              onChange={v => {
                setValue('visiting_route', v);
              }}
              width="140px"
            />
            <Form.MuiTextField
              {...register('visiting_route_etc')}
              placeholder="Enter"
              fullWidth={false}
              required={false}
              disabled={disabled}
            />
          </RowContentDialysis>
        </RowContainer>
      ),
    },
    {
      label: 'Blood vessel Type',
      element: (
        <>
          <RowContainer xs={12}>
            <RowContentDialysis
              title={'Blood vessel Type'}
              titleRatio={1}
              childrenRatio={11}
            >
              <Form.MuiRadioGroup
                disabled={disabled}
                i18nKey="DIALYSIS.DIALYSIS_INFO.BLOOD_VESSEL"
                values={[1, 2, 3, 4, 5, 6, 0]}
                defaultValue={getValues('vascular_access')}
                onChange={v => {
                  setValue('vascular_access', v);
                }}
                width="140px"
              />
              <Form.MuiTextField
                {...register('vascular_access_etc')}
                placeholder="Enter"
                fullWidth={false}
                required={false}
                disabled={disabled}
              />
            </RowContentDialysis>
          </RowContainer>
        </>
      ),
    },
    {
      label: 'Starting Nurse',
      element: (
        <>
          <RowContainer xs={12}>
            <RowContent
              title={'Starting Nurse'}
              titleRatio={1}
              childrenRatio={5}
            >
              <Form.MuiTextField
                {...register('starting_nurse')}
                required={false}
                disabled={disabled}
              />
            </RowContent>
            <RowContent
              title={'Termination Nurse'}
              titleRatio={1}
              childrenRatio={5}
            >
              <Form.MuiTextField
                {...register('ending_nurse')}
                required={false}
                disabled={disabled}
              />
            </RowContent>
          </RowContainer>
        </>
      ),
    },
  ];
  return (
    <>
      <SectionTitle title="Dialysis Information" />
      <RowContainer xs={12}>
        {contents.map(({ label, element }) => {
          if (
            label === 'Blood vessel Type' ||
            label === 'Starting Nurse' ||
            label === 'Dialysate Solution' ||
            label === 'Visiting route'
          ) {
            return <>{element}</>;
          }

          return (
            <>
              <RowContent
                key={label}
                title={label}
                titleRatio={1}
                childrenRatio={5}
              >
                {element}
              </RowContent>
            </>
          );
        })}
      </RowContainer>
    </>
  );
};

export default DialysisInfo;
