import { useState, useEffect } from 'react';

import Form from 'components/Form';
import { IFormRegister, IFormValues, IFormWatch } from 'routes/Main/type';

import { AccessTime } from '@mui/icons-material';
import { MobileTimePicker } from '@mui/x-date-pickers';
import { Grid, Box, MenuItem } from '@mui/material';

import MuiTextField from 'components/Form/MuiTextField';

import RowContainer from '../../components/RowContainer';
import RowContent from '../../components/RowContent';
import SectionTitle from '../../components/SectionTitle';

interface Props extends IFormRegister, IFormValues, IFormWatch {
  disabled?: boolean;
}

const OpContent = (props: Props) => {
  const { disabled, register, watch, setValue, getValues } = props;

  const [methodEtc, setMethodEtc] = useState(0);

  const method_labels = ['Local', 'General', 'Spinal', 'Epidural'];
  const timePickers = [
    { title: 'Patient Arrival', variable: 'surgery_details.arrival_time' },
    {
      title: 'Start Anesthesia',
      variable: 'surgery_details.anesthesia_start_time',
    },
    { title: 'Start Surgery', variable: 'surgery_details.surgery_start_time' },
    { title: 'End Surgery', variable: 'surgery_details.surgery_end_time' },
    {
      title: 'End Anesthesia',
      variable: 'surgery_details.anesthesia_end_time',
    },
    { title: 'Patient Exit', variable: 'surgery_details.discharge_time' },
  ];

  useEffect(() => {
    if (
      ![...method_labels, undefined].includes(
        getValues('surgery_details.anesthetic_method')
      )
    ) {
      setValue(
        'surgery_details.anesthetic_method_etc',
        getValues('surgery_details.anesthetic_method')
      );
      setMethodEtc(1);
    }
  }, []);

  return (
    <>
      <SectionTitle title="Details" />
      <RowContainer xs={12}>
        <Grid item xs={12}>
          <Form.MuiTextField
            multiline
            minRows={15}
            disabled={disabled}
            required={false}
            {...register('surgery_details.content')}
          />
        </Grid>
        {timePickers.map(({ title, variable }, _) => (
          <RowContent
            key={variable}
            title={title}
            titleRatio={1}
            childrenRatio={2}
          >
            <MobileTimePicker
              disabled={disabled}
              value={watch(variable) || null}
              onChange={value => setValue(variable, value)}
              renderInput={params => (
                <MuiTextField
                  {...params}
                  required={false}
                  placeholder="00:00"
                  InputProps={{ endAdornment: <AccessTime /> }}
                />
              )}
            />
          </RowContent>
        ))}
        <RowContent title="Anesthetic Method" titleRatio={1} childrenRatio={2}>
          <MuiTextField
            {...register('surgery_details.anesthetic_method')}
            required={false}
            disabled={disabled}
          />
        </RowContent>
        <RowContent title="Anesthesiologist" titleRatio={1} childrenRatio={2}>
          <MuiTextField
            {...register('surgery_details.anesthesiologist')}
            required={false}
            disabled={disabled}
          />
        </RowContent>
      </RowContainer>
    </>
  );
};

export default OpContent;
