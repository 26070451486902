import { Grid, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';

import {
  SurveyDialogProps,
  TIntakeOutputDefaultValues,
} from 'routes/Main/Survey/type';
import MuiDialog from 'components/MuiDialog';
import { findKeyValueToStr } from 'utils/convert';
import { updatIntakeOutput } from 'apis/survey';
import useSurvey from 'store/survey/useSurvey';
import useNotification from 'hooks/useNotification';
import { IIORCheckDetailCord } from 'apis/survey/type';

import CommonPatientInfo from '../components/CommonPatientInfo';

import SocialHistory from './SocialHistory';

const IORecord = (props: SurveyDialogProps<TIntakeOutputDefaultValues>) => {
  const {
    title,
    isOpen,
    disabled,
    defaultValues,
    user_id,
    nurseName,
    patientInfo,
    onClose,
  } = props;

  const { onUpdateIsSave } = useSurvey();
  const { onSuccess, onFail, onResultCode, onRequired } = useNotification();

  const { handleSubmit, register, getValues, setValue, watch, control } =
    useForm({
      defaultValues,
    });

  const onSubmit = (data: TIntakeOutputDefaultValues) => {
    const { intake_output_survey } = data;

    const request = {
      user_id,
      patient_id: patientInfo.patient_id,
      intake_output_survey: intake_output_survey?.map(
        ({
          date,
          duty,
          total,

          intake1,
          intake2,
          intake3,
          intake4,
          intake5,
          intake6,

          intake_two1,
          intake_two2,
          intake_two3,
          intake_two4,
          intake_two5,
          intake_two6,

          intake_three1,
          intake_three2,
          intake_three3,
          intake_three4,
          intake_three5,
          intake_three6,

          intake_four1,
          intake_four2,
          intake_four3,
          intake_four4,
          intake_four5,
          intake_four6,

          intake_five1,
          intake_five2,
          intake_five3,
          intake_five4,
          intake_five5,
          intake_five6,

          output1,
          output2,
          output3,
          output4,
          output5,
          output6,

          output_two1,
          output_two2,
          output_two3,
          output_two4,
          output_two5,
          output_two6,

          output_three1,
          output_three2,
          output_three3,
          output_three4,
          output_three5,
          output_three6,

          output_four1,
          output_four2,
          output_four3,
          output_four4,
          output_four5,
          output_four6,

          output_five1,
          output_five2,
          output_five3,
          output_five4,
          output_five5,
          output_five6,
        }: IIORCheckDetailCord) => ({
          date,
          duty,
          total,

          intake1,
          intake2,
          intake3,
          intake4,
          intake5,
          intake6,

          intake_two1,
          intake_two2,
          intake_two3,
          intake_two4,
          intake_two5,
          intake_two6,

          intake_three1,
          intake_three2,
          intake_three3,
          intake_three4,
          intake_three5,
          intake_three6,

          intake_four1,
          intake_four2,
          intake_four3,
          intake_four4,
          intake_four5,
          intake_four6,

          intake_five1,
          intake_five2,
          intake_five3,
          intake_five4,
          intake_five5,
          intake_five6,

          output1,
          output2,
          output3,
          output4,
          output5,
          output6,

          output_two1,
          output_two2,
          output_two3,
          output_two4,
          output_two5,
          output_two6,

          output_three1,
          output_three2,
          output_three3,
          output_three4,
          output_three5,
          output_three6,

          output_four1,
          output_four2,
          output_four3,
          output_four4,
          output_four5,
          output_four6,

          output_five1,
          output_five2,
          output_five3,
          output_five4,
          output_five5,
          output_five6,
        })
      ),
    };

    updatIntakeOutput(request)
      .then(({ data: { rc } }) => {
        if (rc !== 1) return onResultCode(rc);

        onUpdateIsSave(true);
        onSuccess('Saved successfully');
      })
      .catch(e => onFail('Failed to save', e));
  };

  const formProps = {
    disabled,
    watch,
    register,
    getValues,
    setValue,
    onSuccess,
    onRequired,
    control,
  };

  return (
    <MuiDialog.SurveyForm
      title={title}
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={disabled ? undefined : handleSubmit(onSubmit)}
      update_at={defaultValues?.update_at}
    >
      <Grid
        container
        wrap="wrap"
        rowSpacing={5}
        columnSpacing={2}
        sx={{ py: 5, px: 1 }}
      >
        <Typography
          sx={{
            margin: '40px auto 0px auto',
            fontWeight: '700',
            fontSize: '16px',
            textAlign: 'center',
          }}
        >
          Intake and Output Record
        </Typography>
        <CommonPatientInfo nurseName={nurseName} patientInfo={patientInfo} />
        <SocialHistory {...formProps} />
      </Grid>
    </MuiDialog.SurveyForm>
  );
};

export default IORecord;
