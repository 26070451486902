import * as React from 'react';
import { Fragment, useState } from 'react';

import { MenuItem, Stack, TextField, Typography } from '@mui/material';

import MuiRadioGroup from './MuiRadioGroup';
import { Grid, Box } from '@mui/material';

import Form from 'components/Form';
import { IFormRegister, IFormValues } from 'routes/Main/type';

import SectionTitle from '../components/SectionTitle';
import RowContainer from '../components/RowContainer';
import RowContent from '../components/RowContent';
import RowContentTwo from './components/RowContent';

interface Props extends IFormRegister, IFormValues {
  disabled?: boolean;
  dietList: string[];
  setDietList: (dietList: string[]) => void;
}

const checks = [
  {
    label: 'Nothing orally',
    key: 'dinner.default_diet.checked1',
  },
  {
    label: 'SOW (Sips of Water)',
    key: 'dinner.default_diet.checked2',
  },
  {
    label: 'LD (Liquid Diet)',
    key: 'dinner.default_diet.checked3',
  },
  {
    label: 'SD (Soft Diet)',
    key: 'dinner.default_diet.checked4',
  },
  {
    label: 'RD (Regular Diet)',
    key: 'dinner.default_diet.checked5',
  },
  {
    label: 'TD (Tolerable Diet)',
    key: 'dinner.default_diet.checked6',
  },
  {
    label: 'NG (Naso Gastric)',
    key: 'dinner.default_diet.checked7',
  },
  {
    label: 'TPN (Total Parenteral Nutrition)',
    key: 'dinner.default_diet.checked8',
  },
];

const checks2 = [
  {
    label: 'Diabetes mellitus',
    key: 'dinner.treatment.checked1',
  },
  {
    label: 'Obesity',
    key: 'dinner.treatment.checked2',
  },
  {
    label: 'Cardiovascular Disease',
    key: 'dinner.treatment.checked3',
  },
  {
    label: 'Disease Of Digestive System',
    key: 'dinner.treatment.checked4',
  },
  {
    label: 'Osteoporosis',
    key: 'dinner.treatment.checked5',
  },
  {
    label: 'Constipation',
    key: 'dinner.treatment.checked6',
  },
  {
    label: 'Anemia',
    key: 'dinner.treatment.checked7',
  },
  {
    label: 'Urolithiasis',
    key: 'dinner.treatment.checked8',
  },
  {
    label: 'Gout',
    key: 'dinner.treatment.checked9',
  },
  {
    label: 'Radiation Therapy',
    key: 'dinner.treatment.checked10',
  },
  {
    label: 'Stomach Cancer',
    key: 'dinner.treatment.checked11',
  },
  {
    label: 'Colorectal Cancer',
    key: 'dinner.treatment.checked12',
  },
  {
    label: 'Liver Cancer',
    key: 'dinner.treatment.checked13',
  },
  {
    label: 'Breast Cancer',
    key: 'dinner.treatment.checked14',
  },
  {
    label: 'Lung Cancer',
    key: 'dinner.treatment.checked15',
  },
  {
    label: 'Esophageal Cancer',
    key: 'dinner.treatment.checked16',
  },
  {
    label: 'Pancreatic Cancer',
    key: 'dinner.treatment.checked17',
  },
  {
    label: 'Prostate Cancer',
    key: 'dinner.treatment.checked18',
  },
  {
    label: 'Thyroid Cancer',
    key: 'dinner.treatment.checked19',
  },
  {
    label: 'Hematologic Malignancy',
    key: 'dinner.treatment.checked20',
  },
  {
    label: 'Head And Neck Cancer',
    key: 'dinner.treatment.checked21',
  },
  {
    label: 'Gastroesophageal Reflux Disease',
    key: 'dinner.treatment.checked22',
  },
  {
    label: 'Renal Disease',
    key: 'dinner.treatment.checked23',
  },
  {
    label: 'Renal Failure',
    key: 'dinner.treatment.checked24',
  },
];

const DietSelection3 = (props: Props) => {
  const { disabled, register, getValues, setValue, dietList, setDietList } =
    props;

  const 적용급식1 = checks
    .filter((item, i) => getValues(item.key))
    .map(v => v.label);

  const 적용급식2 = checks2
    .filter((item, i) => getValues(item.key))
    .map(v => v.label);

  const 적용급식필터링1 = 적용급식1.join(', ');
  const 적용급식필터링2 = 적용급식2.join(', ');

  const total = 적용급식필터링1 + ' ' + 적용급식필터링2;

  return (
    <>
      <SectionTitle title="Dinner" />

      <RowContainer xs={12}>
        <RowContent
          title="Calories(Kcal)"
          titleRatio={1.3}
          childrenRatio={10.7}
        >
          <Stack direction="row" spacing={1}>
            <Form.MuiTextField
              required={false}
              fullWidth
              placeholder="Enter if necessary"
              InputProps={{ ...Form.adornment('', 'Kcal') }}
              {...register('dinner.calorie')}
            />
          </Stack>
        </RowContent>
        <RowContent title="Dinner" titleRatio={10.5} childrenRatio={1.5}>
          <Stack direction="row" spacing={1}></Stack>
        </RowContent>
        <RowContentTwo title="" titleRatio={0} childrenRatio={12}>
          <Form.MuiTextField
            fullWidth
            InputProps={{ readOnly: true }}
            placeholder={total.length > 0 ? total : 'Dinner List'}
            multiline
            minRows={3}
          />
        </RowContentTwo>
      </RowContainer>
      <Box sx={{ width: '95%', margin: '30px auto 0px auto' }}>
        <Typography sx={{ fontSize: '12px', fontWeight: 500 }}>
          Basic Diet
        </Typography>

        <Grid container xs={12}>
          {checks.map((v, i) => (
            <Grid item xs={3}>
              <Form.MuiCheckbox
                label={v.label}
                disabled={disabled}
                defaultValue={Boolean(getValues(v.key)) ? [v.label] : []}
                onChange={(_, checked) => {
                  setValue(v.key, checked);
                  if (checked) setDietList([...dietList, v.label]);
                  else
                    setDietList([
                      ...dietList.filter(value => value !== v.label),
                    ]);
                }}
              />
            </Grid>
          ))}
        </Grid>

        <br />
        <Typography sx={{ fontSize: '12px', fontWeight: 500 }}>
          Therapeutic Diet
        </Typography>
        <Grid container xs={12}>
          {checks2.map((v, i) => (
            <Grid item xs={3}>
              <Form.MuiCheckbox
                label={v.label}
                disabled={disabled}
                defaultValue={Boolean(getValues(v.key)) ? [v.label] : []}
                onChange={(_, checked) => {
                  setValue(v.key, checked);
                  if (checked) setDietList([...dietList, v.label]);
                  else
                    setDietList([
                      ...dietList.filter(value => value !== v.label),
                    ]);
                }}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};

export default DietSelection3;
