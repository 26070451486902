import { Fragment } from 'react';
import { Grid, Box, Typography, useTheme } from '@mui/material';

import MuiTable from 'components/MuiTable';
import { IFormValues, IFormWatch } from 'routes/Main/type';

import useTableForm from '../../hooks/useTableForm';

interface Props extends IFormValues, IFormWatch {
  disabled?: boolean;
}

const BAIContents = (props: Props) => {
  const { palette } = useTheme();

  const { radioGroup, sumValues } = useTableForm(props);
  const { watch, setValue } = props;

  const columns = [
    { fieldId: 'title', label: 'Questions' },
    { fieldId: '0', label: 'Not at all. (0)', sx: { textAlign: 'center' } },
    {
      fieldId: '1',
      label: "Mildly, but didn't \nbother me much. (1)",
      sx: { textAlign: 'center' },
    },
    {
      fieldId: '2',
      label: 'Moderately, it wasn’t \npleasant at times (2)',
      sx: { textAlign: 'center' },
    },
    {
      fieldId: '3',
      label: 'Severely, it bothered \nme a lot. (3)',
      sx: { textAlign: 'center' },
    },
  ];

  const rows = [
    {
      id: 'content[0]',
      title: 'Numbness or tingling',
      ...radioGroup({
        key: 'content[0]',
        options: [0, 1, 2, 3],
        width: '25px',
      }),
    },
    {
      id: 'content[1]',
      title: 'Feeling hot',
      ...radioGroup({
        key: 'content[1]',
        options: [0, 1, 2, 3],
        width: '25px',
      }),
    },
    {
      id: 'content[2]',
      title: 'Wobbliness in legs',
      ...radioGroup({
        key: 'content[2]',
        options: [0, 1, 2, 3],
        width: '25px',
      }),
    },
    {
      id: 'content[3]',
      title: 'Unable to relax',
      ...radioGroup({
        key: 'content[3]',
        options: [0, 1, 2, 3],
        width: '25px',
      }),
    },
    {
      id: 'content[4]',
      title: 'Fear of the worst happening',
      ...radioGroup({
        key: 'content[4]',
        options: [0, 1, 2, 3],
        width: '25px',
      }),
    },
    {
      id: 'content[5]',
      title: 'Dizzy or lightheaded',
      ...radioGroup({
        key: 'content[5]',
        options: [0, 1, 2, 3],
        width: '25px',
      }),
    },
    {
      id: 'content[6]',
      title: 'Heart pounding/racing',
      ...radioGroup({
        key: 'content[6]',
        options: [0, 1, 2, 3],
        width: '25px',
      }),
    },
    {
      id: 'content[7]',
      title: 'Unsteady',
      ...radioGroup({
        key: 'content[7]',
        options: [0, 1, 2, 3],
        width: '25px',
      }),
    },
    {
      id: 'content[8]',
      title: 'Terrified or afraid',
      ...radioGroup({
        key: 'content[8]',
        options: [0, 1, 2, 3],
        width: '25px',
      }),
    },
    {
      id: 'content[9]',
      title: 'Nervous',
      ...radioGroup({
        key: 'content[9]',
        options: [0, 1, 2, 3],
        width: '25px',
      }),
    },
    {
      id: 'content[10]',
      title: 'Feeling of choking',
      ...radioGroup({
        key: 'content[10]',
        options: [0, 1, 2, 3],
        width: '25px',
      }),
    },
    {
      id: 'content[11]',
      title: 'Hands trembling',
      ...radioGroup({
        key: 'content[11]',
        options: [0, 1, 2, 3],
        width: '25px',
      }),
    },
    {
      id: 'content[12]',
      title: 'Shaky/Unsteady',
      ...radioGroup({
        key: 'content[12]',
        options: [0, 1, 2, 3],
        width: '25px',
      }),
    },
    {
      id: 'content[13]',
      title: 'Fear of losing control',
      ...radioGroup({
        key: 'content[13]',
        options: [0, 1, 2, 3],
        width: '25px',
      }),
    },
    {
      id: 'content[14]',
      title: 'Difficulty in breathing',
      ...radioGroup({
        key: 'content[14]',
        options: [0, 1, 2, 3],
        width: '25px',
      }),
    },
    {
      id: 'content[15]',
      title: 'Fear of dying',
      ...radioGroup({
        key: 'content[15]',
        options: [0, 1, 2, 3],
        width: '25px',
      }),
    },
    {
      id: 'content[16]',
      title: 'Scared',
      ...radioGroup({
        key: 'content[16]',
        options: [0, 1, 2, 3],
        width: '25px',
      }),
    },
    {
      id: 'content[17]',
      title: 'Indigestion',
      ...radioGroup({
        key: 'content[17]',
        options: [0, 1, 2, 3],
        width: '25px',
      }),
    },
    {
      id: 'content[18]',
      title: 'Faint/Lightheaded',
      ...radioGroup({
        key: 'content[18]',
        options: [0, 1, 2, 3],
        width: '25px',
      }),
    },
    {
      id: 'content[19]',
      title: 'Face flushed',
      ...radioGroup({
        key: 'content[19]',
        options: [0, 1, 2, 3],
        width: '25px',
      }),
    },
    {
      id: 'content[20]',
      title: 'Hot/Cold sweats',
      ...radioGroup({
        key: 'content[20]',
        options: [0, 1, 2, 3],
        width: '25px',
      }),
    },
  ];

  const watchSumValues = () => {
    const values = rows.map(({ id }) => (watch(id) ? Number(watch(id)) : 0));
    const sum = sumValues(values);
    setValue('sum', sum);
    return sum;
  };

  return (
    <Fragment>
      <Grid item xs={12}>
        <MuiTable columns={columns} rows={rows} />
      </Grid>
      <Grid item xs={12}>
        <Box display={'flex'} flexDirection={'column'} alignItems={'flex-end'}>
          <Typography
            gutterBottom
            minWidth={115}
            fontWeight={700}
            variant="subtitle1"
          >
            Total : {watchSumValues()} point
          </Typography>
          <Typography
            minWidth={115}
            variant="caption"
            sx={{ color: `${palette.primary.main}` }}
          >
            <Typography variant="inherit">
              <Box component={'strong'} mr={0.5}>
                0-21
              </Box>
              : Low Anxiety
            </Typography>
            <Typography variant="inherit">
              <Box component={'strong'} mr={0.5}>
                22-35
              </Box>
              : Moderate Anxiety
            </Typography>
            <Typography variant="inherit">
              <Box component={'strong'} mr={0.5}>
                36 And Above
              </Box>
              : Potentially Concerning Levels Of Anxiety
            </Typography>
          </Typography>
        </Box>
      </Grid>
    </Fragment>
  );
};

export default BAIContents;
