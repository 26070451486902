import { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  RadioGroup,
  Radio,
  Table,
  TableBody,
  TableHead,
  TableRow,
  useTheme,
  TableContainer,
} from '@mui/material';
import {
  StyledTableCellTwo,
  StyledTableCellWithoutLeftTwo,
  StyledTableCellWithoutRightTwo,
  StyledTableCellWithoutLeftRightTwo,
} from 'routes/Main/style';
import SectionTitle from '../components/SectionTitle';

import { IFormValues, IFormWatch } from 'routes/Main/type';

const radioId = ['eye_opening', 'verbal_response', 'motor_response'];
const contentLabel = [
  {
    id: 1,
    ko: 'Eye Opening',
    desc: ['No response', 'To Pain', 'To verbal command', 'Spontaneously'],
  },
  {
    id: 2,
    ko: 'Verbal Response',
    desc: [
      'No response',
      'Incomprehensible sounds',
      'Inappropriate words',
      'Confused',
      'Oriented to time, place, and person',
    ],
  },
  {
    id: 3,
    ko: 'Motor Response',
    desc: [
      'No response',
      'Abnormal extension',
      'Abnormal flexion ',
      'Flexion withdrawal from pain',
      'Moves to localized pain',
      'Obeys commands',
    ],
  },
];
const scoreLabel = [
  { score: '15', label: 'ALERT' },
  { score: '13~14', label: 'DROWSY' },
  { score: '8~12', label: 'STUPOR' },
  { score: '4~7', label: 'SEMI-COMA' },
  { score: '3', label: 'COMA' },
];

interface Props extends IFormValues, IFormWatch {
  disabled?: boolean;
}
const GCSContents = (props: Props) => {
  const { palette } = useTheme();

  const { disabled, setValue, getValues } = props;

  const [sumValue, setSumValue] = useState(0);

  const calculateSumValue = () => {
    setSumValue(
      radioId.reduce((acc, cur) => {
        const value = Number(getValues(cur));
        return value ? acc + value : acc;
      }, 0)
    );
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setValue(e.target.name, e.target.value);
    calculateSumValue();
  };

  useEffect(() => {
    calculateSumValue();
  }, []);

  return (
    <>
      <SectionTitle title="GCS (Glasgow Coma Scale)" />
      <Box sx={{ width: '98%', margin: '30px 20px' }}>
        <TableContainer>
          <TableHead>
            <TableRow>
              <StyledTableCellTwo
                colSpan={2}
                sx={{
                  padding: '16px',
                  paddingLeft: '14px',
                  whiteSpace: 'nowrap',
                }}
              >
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <Typography sx={{ fontSize: '12px', fontWeight: 500 }}>
                    Response Category
                  </Typography>
                  <Typography sx={{ fontSize: '12px', fontWeight: 500 }}>
                    Score
                  </Typography>
                </div>
              </StyledTableCellTwo>
              <StyledTableCellWithoutLeftTwo
                colSpan={2}
                sx={{ padding: '16px', paddingLeft: '103px' }}
              >
                Response
              </StyledTableCellWithoutLeftTwo>
              <StyledTableCellTwo></StyledTableCellTwo>
            </TableRow>
          </TableHead>

          <TableBody>
            {contentLabel.map(
              (content: { id: number; ko: string; desc: string[] }, i) => (
                <TableRow>
                  <StyledTableCellTwo
                    align="center"
                    sx={{
                      padding: '16px',
                      width: '300px',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {content.ko}
                  </StyledTableCellTwo>
                  <StyledTableCellWithoutRightTwo>
                    {content.desc.map((_, i) => (
                      <TableRow sx={{ height: '44px', lineHeight: '44px' }}>
                        <Box
                          sx={{
                            minWidth: '75px',
                            textAlign: 'center',
                            marginLeft: '55px',
                          }}
                        >
                          {i + 1}
                        </Box>
                      </TableRow>
                    ))}
                  </StyledTableCellWithoutRightTwo>
                  <StyledTableCellWithoutLeftRightTwo>
                    {content.desc.map((v, i) => (
                      <TableRow
                        sx={{
                          height: '44px',
                          lineHeight: '44px',
                        }}
                      >
                        <Box sx={{ paddingLeft: '100px', minWidth: '500px' }}>
                          {v}
                        </Box>
                      </TableRow>
                    ))}
                  </StyledTableCellWithoutLeftRightTwo>
                  <StyledTableCellWithoutLeftTwo>
                    <RadioGroup
                      name={radioId[content.id - 1]}
                      defaultValue={Number(getValues(radioId[content.id - 1]))}
                    >
                      {content.desc.map((_, i) => (
                        <TableRow
                          sx={{
                            height: '44px',
                            lineHeight: '44px',
                            textAlign: 'center',
                          }}
                        >
                          <Box
                            sx={{
                              width: '300px',
                              paddingLeft: '550px',
                              paddingRight: '70px',
                            }}
                          >
                            <Radio
                              disabled={disabled}
                              name={radioId[content.id - 1]}
                              value={i + 1}
                              onChange={handleChange}
                            />
                          </Box>
                        </TableRow>
                      ))}
                    </RadioGroup>
                  </StyledTableCellWithoutLeftTwo>
                </TableRow>
              )
            )}
          </TableBody>
        </TableContainer>
        <Box
          display={'flex'}
          flexDirection={'column'}
          alignItems={'flex-end'}
          sx={{ marginTop: '20px' }}
        >
          <Typography
            gutterBottom
            minWidth={115}
            fontWeight={700}
            variant="subtitle1"
            sx={{ marginRight: '16px' }}
          >
            Total : {sumValue}
          </Typography>
          <Typography
            minWidth={115}
            variant="caption"
            sx={{ color: `${palette.primary.main}` }}
          >
            {scoreLabel.map(({ score, label }) => (
              <Typography variant="inherit">
                <Box component={'strong'} mr={0.5}>
                  {score} scores:
                </Box>
                {label}
              </Typography>
            ))}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default GCSContents;
