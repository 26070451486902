import Form from 'components/Form';

import { Fragment, useState } from 'react';
import { Delete } from '@mui/icons-material';
import { Button, Grid, IconButton, MenuItem } from '@mui/material';

import { Ti18nId } from 'hooks/useI18n';
import { IECardexImageTest } from 'apis/survey/type';
import { IFormRegister, IFormValues, IFormWatch } from 'routes/Main/type';
import MuiTable from 'components/MuiTable';
import MuiTextField from 'components/Form/MuiTextField';

interface Props extends IFormValues, IFormWatch, IFormRegister {
  disabled?: boolean;
  onRequired: (id: Ti18nId) => void;
  onSuccess: (message: string) => void;
}

const ImageTest = (props: Props) => {
  const { disabled, watch, setValue, onRequired, onSuccess, register } = props;
  const imageTestList: IECardexImageTest[] = watch('imaging_test_data');

  const etcSelcet = ['Reception', 'Inspection', 'Enter manually'];

  const [date, setDate] = useState('');
  const [imageTest, setImageTest] = useState('');
  const [receipt, setReceipt] = useState('');
  const [receiptEtc, setReceiptEtc] = useState('');
  const [result, setResult] = useState('');

  const columns = [
    { fieldId: 'date', label: 'Date', sx: { width: 200 } },
    { fieldId: 'imaging_test', label: 'Imaging Test' },
    {
      fieldId: 'implementing_and_inspection',
      label: 'Implementing Inspection',
      sx: { width: 200 },
    },
    { fieldId: 'result', label: 'Result', sx: { width: 200 } },
    { fieldId: 'action', label: '', sx: { width: 100 } },
  ];

  const onAddRow = () => {
    const request = {
      date,
      imaging_test: imageTest,
      implementing_and_inspection:
        receipt === 'Enter manually' ? receiptEtc : receipt,
      result,
    };

    onSuccess('Added successfully');
    setValue(
      'imaging_test_data',
      imageTestList ? [...imageTestList, request] : [request]
    );
    setValue('etc.image_test.date', '');
    setDate('');
    setImageTest('');
    setReceipt('');
    setResult('');
  };

  const inputRow = {
    id: 'add-image_test',
    date: (
      <Form.MuiTextField
        type="date"
        required={false}
        disabled={disabled}
        InputLabelProps={{ shrink: true }}
        {...register('etc.image_test.date', {
          onChange: e => setDate(e.target.value),
        })}
      />
    ),
    imaging_test: (
      <MuiTextField
        value={imageTest}
        required={false}
        onChange={({ target: { value } }) => setImageTest(value)}
      />
    ),
    implementing_and_inspection: (
      <div style={{ display: 'flex' }}>
        <MuiTextField
          select
          value={receipt}
          required={false}
          onChange={({ target: { value } }) => setReceipt(value)}
        >
          {etcSelcet.map(option => {
            return <MenuItem value={option}>{option}</MenuItem>;
          })}
        </MuiTextField>
        {receipt === 'Enter manually' && (
          <MuiTextField
            sx={{ marginLeft: '10px' }}
            value={receiptEtc}
            onChange={({ target: { value } }) => setReceiptEtc(value)}
          />
        )}
      </div>
    ),
    result: (
      <MuiTextField
        value={result}
        required={false}
        onChange={({ target: { value } }) => setResult(value)}
      />
    ),
    action: (
      <Button variant="contained" size="small" onClick={onAddRow}>
        ADD
      </Button>
    ),
  };

  const onDeleteRow = (index: number) => {
    setValue(
      'imaging_test_data',
      imageTestList.filter((_, i) => i !== index)
    );
  };

  const displayRows = imageTestList
    ? imageTestList.map((item, i) => ({
        ...item,
        id: i,
        action: (
          <IconButton
            size="small"
            onClick={() => onDeleteRow(i)}
            sx={{ display: disabled ? 'none' : 'block' }}
          >
            <Delete />
          </IconButton>
        ),
      }))
    : [];
  const tableRow = disabled ? displayRows : [inputRow, ...displayRows];

  return (
    <Fragment>
      <Grid item xs={12}>
        <MuiTable columns={columns} rows={[...tableRow]} />
      </Grid>
    </Fragment>
  );
};

export default ImageTest;
