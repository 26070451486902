import { useForm } from 'react-hook-form';

import useSurvey from 'store/survey/useSurvey';
import useNotification from 'hooks/useNotification';

import { Typography, Grid } from '@mui/material';
import MuiDialog from 'components/MuiDialog';

import { SurveyDialogProps, TBAIDefaultValues } from '../../type';

import CommonPatientInfo from '../../components/CommonPatientInfo';
import BAIContents from './BAIContents';

import { updateBAI } from 'apis/survey';

const BAI = (props: SurveyDialogProps<TBAIDefaultValues>) => {
  const {
    title,
    isOpen,
    disabled,
    defaultValues,
    user_id,
    nurseName,
    patientInfo,
    onClose,
  } = props;

  const { onUpdateIsSave } = useSurvey();
  const { onSuccess, onFail, onResultCode, onRequired } = useNotification();
  const { handleSubmit, watch, register, getValues, setValue } = useForm({
    defaultValues,
  });

  const onSubmit = (data: TBAIDefaultValues) => {
    const { patient_id } = patientInfo;
    const { content, sum } = data;

    if (content.length < 21) return onRequired('REQUIRED.NONCHOICE');

    const request = {
      user_id,
      patient_id,
      bai_survey: { content, sum },
    };

    updateBAI(request)
      .then(({ data: { rc } }) => {
        if (rc !== 1) return onResultCode(rc);
        onUpdateIsSave(true);
        onSuccess('Saved successfully');
      })
      .catch(e => onFail('Failed to save', e));
  };

  const formProps = {
    disabled,
    watch,
    register,
    getValues,
    setValue,
    onSuccess,
    onRequired,
  };

  return (
    <MuiDialog.SurveyForm
      title={title}
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={disabled ? undefined : handleSubmit(onSubmit)}
      update_at={defaultValues?.update_at}
    >
      <Grid
        container
        wrap="wrap"
        rowSpacing={5}
        columnSpacing={3}
        sx={{ py: 5, px: 1 }}
      >
        <Typography
          sx={{
            margin: '40px auto 0px auto',
            fontWeight: '700',
            fontSize: '16px',
            textAlign: 'center',
          }}
        >
          Beck Anxiety Inventory : BAI
          <Typography
            sx={{ marginTop: '15px', fontWeight: '400', fontSize: '14px' }}
          >
            Please take a moment to review the list of common symptoms of
            anxiety provided below. Carefully read each item and indicate how
            much
            <br />
            you have been bothered by that symptom during the past month,
            including today, by selecting your current state as 0-3 points.
          </Typography>
        </Typography>
        <CommonPatientInfo patientInfo={patientInfo} nurseName={nurseName} />
        <BAIContents {...formProps} />
      </Grid>
    </MuiDialog.SurveyForm>
  );
};

export default BAI;
