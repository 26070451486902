import { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  RadioGroup,
  Radio,
  Table,
  TableBody,
  TableHead,
  TableRow,
  useTheme,
  TableContainer,
} from '@mui/material';
import {
  StyledTableCellTwo,
  StyledTableCellWithoutLeftTwo,
  StyledTableCellWithoutRightTwo,
  StyledTableCellWithoutLeftRightTwo,
} from 'routes/Main/style';
import SectionTitle from '../components/SectionTitle';

import { IFormValues, IFormWatch } from 'routes/Main/type';

const radioId = [
  'eye_opening',
  'brainstem_reflexes',
  'motor_response',
  'respiration',
];
const contentLabel = [
  {
    id: 1,
    ko: 'Eye Opening',
    desc: [
      'Eyelids remain closed with pain',
      'Eyelids closed but open to pain',
      'Eyelids closed but open to loud voice',
      'Eyelids open but not tracking',
      'Eyelids open or opened, tracking, or blinking to command',
    ],
  },
  {
    id: 2,
    ko: 'Motor Response',
    desc: [
      'No response to pain or generalized myoclonus status',
      'Extension response to pain',
      'Flexion response to pain',
      'Localizing to pain',
      'Thumbs-up, fist, or peace sign',
    ],
  },
  {
    id: 3,
    ko: 'Brainstem Reflexes',
    desc: [
      'Absent pupil, corneal, and cough reflexes',
      'Pupil and corneal reflexes absent',
      'Pupil OR corneal reflex absent',
      'One pupil wide and fixed',
      'Pupil and corneal reflexes present',
    ],
  },
  {
    id: 4,
    ko: 'Respiration',
    desc: [
      'Breathes at ventilator rate or apnea',
      'Breathes above ventilatory rate',
      'Not intubated, irregular breathing',
      'Not intubated, Cheyne-Stokes breathing pattern',
      'Not intubated, regular breathing pattern',
    ],
  },
];
const scoreLabel = [{ score: '15', label: 'ALERT' }];

interface Props extends IFormValues, IFormWatch {
  disabled?: boolean;
}
const FourScoreContents = (props: Props) => {
  const { palette } = useTheme();

  const { disabled, setValue, getValues } = props;

  const [sumValue, setSumValue] = useState(0);

  const calculateSumValue = () => {
    setSumValue(
      radioId.reduce((acc, cur) => {
        const value = Number(getValues(cur));
        return value ? acc + value : acc;
      }, 0)
    );
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setValue(e.target.name, e.target.value);
    calculateSumValue();
  };

  useEffect(() => {
    calculateSumValue();
  }, []);

  return (
    <>
      <SectionTitle title="FOUR Score" />
      <Box sx={{ width: '98%', margin: '20px' }}>
        <TableContainer>
          <TableHead>
            <TableRow>
              <StyledTableCellTwo
                colSpan={1}
                sx={{
                  padding: '16px',
                  paddingLeft: '14px',
                  whiteSpace: 'nowrap',
                }}
              >
                Response Category
              </StyledTableCellTwo>
              <StyledTableCellWithoutLeftTwo
                colSpan={1}
                sx={{ padding: '16px', paddingLeft: '60px' }}
              >
                Score
              </StyledTableCellWithoutLeftTwo>
              <StyledTableCellWithoutLeftTwo
                colSpan={2}
                sx={{ padding: '16px', paddingLeft: '100px' }}
              >
                Response
              </StyledTableCellWithoutLeftTwo>
              <StyledTableCellTwo></StyledTableCellTwo>
            </TableRow>
          </TableHead>

          <TableBody>
            {contentLabel.map(
              (content: { id: number; ko: string; desc: string[] }, i) => (
                <TableRow>
                  <StyledTableCellTwo
                    align="center"
                    sx={{
                      padding: '16px',
                      width: '300px',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {content.ko}
                  </StyledTableCellTwo>
                  <StyledTableCellWithoutRightTwo>
                    {content.desc.map((_, i) => (
                      <TableRow sx={{ height: '44px', lineHeight: '44px' }}>
                        <Box
                          sx={{
                            minWidth: '50px',
                            textAlign: 'center',
                            marginLeft: '50px',
                          }}
                        >
                          {i}
                        </Box>
                      </TableRow>
                    ))}
                  </StyledTableCellWithoutRightTwo>
                  <StyledTableCellWithoutLeftRightTwo>
                    {content.desc.map((v, i) => (
                      <TableRow
                        sx={{
                          height: '44px',
                          lineHeight: '44px',
                        }}
                      >
                        <Box sx={{ paddingLeft: '100px', minWidth: '600px' }}>
                          {v}
                        </Box>
                      </TableRow>
                    ))}
                  </StyledTableCellWithoutLeftRightTwo>
                  <StyledTableCellWithoutLeftTwo>
                    <RadioGroup
                      name={radioId[content.id - 1]}
                      defaultValue={Number(getValues(radioId[content.id - 1]))}
                    >
                      {content.desc.map((_, i) => (
                        <TableRow
                          sx={{
                            height: '44px',
                            lineHeight: '44px',
                            textAlign: 'center',
                          }}
                        >
                          <Box
                            sx={{
                              width: '300px',
                              paddingLeft: '400px',
                              paddingRight: '70px',
                            }}
                          >
                            <Radio
                              disabled={disabled}
                              name={radioId[content.id - 1]}
                              value={i}
                              onChange={handleChange}
                            />
                          </Box>
                        </TableRow>
                      ))}
                    </RadioGroup>
                  </StyledTableCellWithoutLeftTwo>
                </TableRow>
              )
            )}
          </TableBody>
        </TableContainer>
        <Box
          display={'flex'}
          flexDirection={'column'}
          alignItems={'flex-end'}
          sx={{ marginTop: '20px' }}
        >
          <Typography
            gutterBottom
            minWidth={115}
            fontWeight={700}
            variant="subtitle1"
          >
            Total : {sumValue}
          </Typography>
          <Typography
            minWidth={115}
            variant="caption"
            sx={{ color: `${palette.primary.main}` }}
          >
            <Typography variant="inherit">
              <Box component={'strong'} mr={0.5}></Box>*A decrease in score on
              the FOUR Score is associated with a worsening level of
              consciousness.
            </Typography>
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default FourScoreContents;
