import { useState } from 'react';

import Form from 'components/Form';
import { IFormRegister, IFormValues, IFormWatch } from 'routes/Main/type';

import RowContainer from '../../components/RowContainer';
import RowContent from '../../components/RowContent';
import SubRowContent from './RowContent';
import SectionTitle from '../../components/SectionTitle';

import {
  Box,
  Typography,
  InputAdornment,
  FormHelperText,
  Stack,
} from '@mui/material';

interface Props extends IFormRegister, IFormValues, IFormWatch {
  disabled?: boolean;
}

const BabyStatus = (props: Props) => {
  const { disabled, register, getValues, setValue, watch } = props;

  const contents = [
    {
      label: 'Gender',
      element: (
        <Form.MuiRadioGroup
          disabled={disabled}
          i18nKey="CHILDBIRTH.BABY_STATUS.GENDER"
          values={[1, 2]}
          defaultValue={getValues('newborn_condition.gender')}
          onChange={v => setValue('newborn_condition.gender', v)}
          width="100px"
        />
      ),
    },
    {
      label: 'Weight',
      element: (
        <Form.MuiTextField
          placeholder="Weight"
          InputProps={{
            endAdornment: <InputAdornment position="end">kg</InputAdornment>,
          }}
          required={false}
          disabled={disabled}
          {...register('newborn_condition.weight')}
        />
      ),
    },
    {
      label: 'Oxygen \nintake',
      element: (
        <Form.MuiRadioGroup
          disabled={disabled}
          i18nKey="CHILDBIRTH.YES_OR_NO"
          values={[1, 2]}
          defaultValue={getValues('newborn_condition.oxygen_intake')}
          onChange={v => setValue('newborn_condition.oxygen_intake', v)}
          width="100px"
        />
      ),
    },
    {
      label: 'First urine',
      element: (
        <Form.MuiRadioGroup
          disabled={disabled}
          i18nKey="CHILDBIRTH.YES_OR_NO"
          values={[1, 2]}
          defaultValue={getValues('newborn_condition.first_urine')}
          onChange={v => setValue('newborn_condition.first_urine', v)}
          width="100px"
        />
      ),
    },
    {
      label: 'Meconium \nRemoval',
      element: (
        <Form.MuiRadioGroup
          disabled={disabled}
          i18nKey="CHILDBIRTH.YES_OR_NO"
          values={[1, 2]}
          defaultValue={getValues('newborn_condition.placenta_discharge')}
          onChange={v => setValue('newborn_condition.placenta_discharge', v)}
          width="100px"
        />
      ),
    },
    {
      label: 'Meconium coloration',
      element: (
        <Form.MuiRadioGroup
          disabled={disabled}
          i18nKey="CHILDBIRTH.YES_OR_NO"
          values={[1, 2]}
          defaultValue={getValues('newborn_condition.fetal_staining')}
          onChange={v => setValue('newborn_condition.fetal_staining', v)}
          width="100px"
        />
      ),
    },
    {
      label: 'Nuchal cord',
      element: (
        <Form.MuiRadioGroup
          disabled={disabled}
          i18nKey="CHILDBIRTH.YES_OR_NO"
          values={[1, 2]}
          defaultValue={getValues('newborn_condition.nuchal_cord')}
          onChange={v => {
            setValue('newborn_condition.nuchal_cord', v);
            if (v == 1) setValue('newborn_condition.nuchal_cord_content', '');
          }}
          width="100px"
        />
      ),
    },
    {
      label: 'Resuscitation',
      element: (
        <Form.MuiRadioGroup
          disabled={disabled}
          i18nKey="CHILDBIRTH.YES_OR_NO"
          values={[1, 2]}
          defaultValue={getValues('newborn_condition.resuscitation')}
          onChange={v => {
            setValue('newborn_condition.resuscitation', v);
            if (v == 1) setValue('newborn_condition.resuscitation_content', '');
          }}
          width="100px"
        />
      ),
    },
    {
      label: 'Appearance deformities and specifications',
      element: (
        <Form.MuiTextField
          required={false}
          disabled={disabled}
          {...register('newborn_condition.specifications')}
        />
      ),
    },
  ];
  return (
    <>
      <SectionTitle title="Newborn Condition" />
      <RowContainer xs={12}>
        {contents.map(({ label, element }) => {
          if (label === 'Oxygen \nintake') {
            return (
              <>
                <SubRowContent
                  title={label}
                  titleRatio={1.2}
                  childrenRatio={1.8}
                >
                  {element}
                </SubRowContent>
              </>
            );
          } else if (label === 'Meconium \nRemoval') {
            return (
              <>
                <SubRowContent
                  title={label}
                  titleRatio={1.2}
                  childrenRatio={1.8}
                >
                  {element}
                </SubRowContent>
              </>
            );
          }
          return (
            <>
              <RowContent
                title={label}
                titleRatio={
                  label === 'Appearance deformities and specifications'
                    ? 2.8
                    : 1.2
                }
                childrenRatio={
                  label === 'Appearance deformities and specifications'
                    ? 9.2
                    : 1.8
                }
              >
                {element}
              </RowContent>
            </>
          );
        })}
      </RowContainer>
    </>
  );
};

export default BabyStatus;
