import { Fragment } from 'react';
import { Grid, GridSize, Typography } from '@mui/material';

interface Props {
  title?: string;
  children?: React.ReactNode;
  titleRatio?: GridSize;
  childrenRatio?: GridSize;
}

const RowContentPreWrap = (props: Props) => {
  const { title, titleRatio = 2, children, childrenRatio = 10 } = props;

  return (
    <Fragment>
      <Grid item xs={titleRatio}>
        <Typography
          variant="caption"
          fontWeight="500"
          lineHeight="24px"
          whiteSpace="pre-wrap"
          fontSize={12}
        >
          {title}
        </Typography>
      </Grid>
      <Grid item xs={childrenRatio}>
        {children}
      </Grid>
    </Fragment>
  );
};

export default RowContentPreWrap;
