import { Fragment } from 'react';

import { AccessTime } from '@mui/icons-material';
import { Checkbox, Grid, Box } from '@mui/material';
import { MobileTimePicker } from '@mui/x-date-pickers';

import { IMedication } from 'apis/survey/type';
import { IFormValues, IFormWatch } from 'routes/Main/type';
import MuiTextField from 'components/Form/MuiTextField';

import SectionTitle from '../components/SectionTitle';
import MuiTable from 'components/MuiTable';
import { formatStringToDate } from 'utils/formatting';

import MuiModal from './MuiModal';
import { ReactComponent as Wiki } from 'assets/wikipedia.svg';
import { ReactComponent as Medi } from 'assets/medi-info-icon.svg';

interface Props extends IFormValues, IFormWatch {
  disabled?: boolean;
}

const Medications = (props: Props) => {
  const { disabled, watch, getValues, setValue } = props;
  const medicationList: IMedication[] = getValues('medication_surveys');

  const columns = [
    { fieldId: 'pt_medication_no', label: 'Prescription\nNo.' },
    {
      fieldId: 'prescription_time',
      label: 'Prescription\nTime',
    },
    { fieldId: 'medication_time', label: 'Administration\nTime' },
    { fieldId: 'medication_name', label: 'Name', width: 150 },
    { fieldId: 'medication_content', label: 'Content' },
    { fieldId: 'medication_measure', label: 'Unit' },
    { fieldId: 'medication_amount', label: 'Dose' },
    { fieldId: 'medication_freq', label: 'Number of \nAdministrations' },
    { fieldId: 'medication_note', label: 'Methods', width: 250 },
    { fieldId: 'medication_do', label: 'Completed' },
    { fieldId: 'medication_info', label: 'Information' },
  ];

  const getRowEditing = (prefix: string) => {
    const medicationTime = watch(`${prefix}.medication_time`);
    const onChangeMedicationTime = (value: any) =>
      setValue(`${prefix}.medication_time`, value);
    const medicationDo = Boolean(watch(`${prefix}.medication_do`));
    const onChangeMedicationDo = (_: any, checked: boolean) =>
      setValue(`${prefix}.medication_do`, checked ? 1 : 0);

    return {
      medicationTime,
      onChangeMedicationTime,
      medicationDo,
      onChangeMedicationDo,
    };
  };

  const rows = medicationList?.map((item, i) => {
    const {
      medicationTime,
      onChangeMedicationTime,
      medicationDo,
      onChangeMedicationDo,
    } = getRowEditing(`medication_surveys.${i}`);

    console.log(
      '??',
      item.medication_name.replaceAll('%', '').replaceAll(/%20/g, '')
    );

    return {
      ...item,
      id: item.pt_medication_no,
      prescription_time: formatStringToDate(item.prescription_time, 'hh:mm a'),
      medication_time: (
        <MobileTimePicker
          value={medicationTime}
          onChange={onChangeMedicationTime}
          renderInput={params => (
            <MuiTextField
              {...params}
              disabled={disabled}
              placeholder="00:00"
              InputProps={{ endAdornment: <AccessTime /> }}
              sx={{ width: 150 }}
            />
          )}
        />
      ),
      medication_do: (
        <Checkbox
          size="small"
          disabled={disabled}
          checked={medicationDo}
          onChange={onChangeMedicationDo}
          sx={{ marginLeft: '16px' }}
        />
      ),
      medication_info: (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Wiki
            style={{ cursor: 'pointer', marginRight: '10px' }}
            onClick={() =>
              window.open(
                'https://en.wikipedia.org/w/index.php?search=' +
                  item.medication_name.replaceAll('%', '').replace(/%20/g, '') +
                  '&title=Special%3ASearch&ns0=1'
              )
            }
          />
        </Box>
      ),
    };
  });

  return (
    <Fragment>
      <SectionTitle title="Prescription History" />
      <Grid item xs={12}>
        <MuiTable columns={columns} rows={rows} />
        <div style={{ paddingLeft: '90%' }}>
          <MuiModal
            watch={watch}
            disabled={disabled}
            getValues={getValues}
            setValue={setValue}
          />
        </div>
      </Grid>
    </Fragment>
  );
};

export default Medications;
