import { useState } from 'react';
import {
  Box,
  Grid,
  Typography,
  RadioGroup,
  Radio,
  Button,
  IconButton,
} from '@mui/material';
import { AccessTime, Delete } from '@mui/icons-material';
import { MobileTimePicker } from '@mui/x-date-pickers';
import { StyledFormControlLabel } from 'routes/Main/style';
import GridItem from '../../components/GridItem';
import MuiTextField from 'components/Form/MuiTextField';
import MuiTable from 'components/MuiTable';

import { Ti18nId } from 'hooks/useI18n';
import { IFormValues, IFormWatch, IFormRegister } from 'routes/Main/type';
import { IFLACC } from 'apis/survey/type';
import { formatStringToDate } from 'utils/formatting';

import theme from 'styles/theme';

interface Props extends IFormValues, IFormWatch, IFormRegister {
  disabled?: boolean;
  onRequired: (id: Ti18nId) => void;
  onSuccess: (message: string) => void;
}

const radioId = ['face', 'legs', 'activity', 'cry', 'consolability'];

const FlaccContents = (props: Props) => {
  const { disabled, watch, setValue, onRequired, onSuccess, register } = props;
  const flaccList: IFLACC[] = watch('flacc_survey');

  const [date, setDate] = useState('');
  const [checkTime, setCheckTime] = useState(null);
  const [sumValue, setSumValue] = useState(0);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>, v: string) => {
    setValue(e.target.name, e.target.value);
    setSumValue(
      radioId.reduce((acc, cur) => {
        const value = Number(watch(cur));
        return value ? acc + value : acc;
      }, 0)
    );
  };

  const columns = [
    { fieldId: 'date', label: 'Date', sx: { width: 200 } },
    { fieldId: 'time', label: 'Time', sx: { width: 200 } },
    { fieldId: 'sum', label: 'Score' },
    { fieldId: 'action', label: '', sx: { width: 100 } },
  ];

  const onAddRow = () => {
    const request = { date, time: checkTime, sum: sumValue };

    if (checkTime === null) return onRequired('REQUIRED.NONCHOICE');
    for (let i = 0; i < radioId.length; i++) {
      if (watch(radioId[i]) === undefined)
        return onRequired('REQUIRED.NONCHOICE');
    }

    onSuccess('Added successfully');
    setValue('flacc_survey', flaccList ? [...flaccList, request] : [request]);
    setValue('flacc_scale_date', '');
    setDate('');
    setCheckTime(null);
  };

  const inputRow = {
    id: 'add-nrs',
    date: (
      <MuiTextField
        type="date"
        required={false}
        disabled={disabled}
        InputLabelProps={{ shrink: true }}
        {...register('flacc_scale_date', {
          onChange: e => setDate(e.target.value),
        })}
      />
    ),
    time: (
      <MobileTimePicker
        value={checkTime}
        onChange={setCheckTime}
        renderInput={params => (
          <MuiTextField
            {...params}
            required={false}
            placeholder="00:00"
            InputProps={{ endAdornment: <AccessTime /> }}
          />
        )}
      />
    ),
    sum: (
      <>
        <MuiTextField value={sumValue} required={true} disabled />
      </>
    ),
    action: (
      <Button variant="contained" size="small" onClick={onAddRow}>
        ADD
      </Button>
    ),
  };

  const onDeleteRow = (index: number) => {
    setValue(
      'flacc_survey',
      flaccList.filter((_, i) => i !== index)
    );
  };

  const displayRows = flaccList
    ? flaccList.map((item, i) => ({
        ...item,
        id: i,
        time: formatStringToDate(item.time, 'hh:mm a'),
        action: (
          <IconButton
            size="small"
            onClick={() => onDeleteRow(i)}
            sx={{ display: disabled ? 'none' : 'block' }}
          >
            <Delete />
          </IconButton>
        ),
      }))
    : [];
  const tableRow = disabled ? displayRows : [inputRow, ...displayRows];

  return (
    <>
      <Box sx={{ width: '100%', marginTop: '40px', marginBottom: '12px' }}>
        <MuiTable columns={columns} rows={tableRow} />
      </Box>
      <Box sx={{ width: '850px', margin: '24px auto' }}>
        <Grid container spacing={1}>
          <GridItem bgColor="#0000001F" text="" fontSize={'14px'} />
          <GridItem bgColor="#0000001F" text="0" fontSize={'14px'} />
          <GridItem bgColor="#0000001F" text="1" fontSize={'14px'} />
          <GridItem bgColor="#0000001F" text="2" fontSize={'14px'} />

          <GridItem bgColor="#0000001F" text="Face" fontSize={'12px'} />
          <RadioGroup
            name={'face'}
            row
            sx={{
              width: '75%',
              display: 'flex',
              justifyContent: 'space-around',
            }}
            onChange={onChange}
          >
            <StyledFormControlLabel
              value={0}
              label={'No particular expression or smile'}
              control={<Radio />}
            />
            <StyledFormControlLabel
              value={1}
              label={'Occasional grimace or frown, withdrawn, disinterested'}
              control={<Radio />}
            />
            <StyledFormControlLabel
              value={2}
              label={'Frequent to constant frown, quivering chin, clenched jaw'}
              control={<Radio />}
            />
          </RadioGroup>

          <GridItem bgColor="#0000001F" text="Legs" fontSize={'12px'} />
          <RadioGroup
            name={'legs'}
            row
            sx={{
              width: '75%',
              display: 'flex',
              justifyContent: 'space-around',
            }}
            onChange={onChange}
          >
            <StyledFormControlLabel
              value={0}
              label={'Normal position or relaxed'}
              control={<Radio />}
            />
            <StyledFormControlLabel
              value={1}
              label={'Uneasy, restless, tense'}
              control={<Radio />}
            />
            <StyledFormControlLabel
              value={2}
              label={'Kicking or legs drawn up'}
              control={<Radio />}
            />
          </RadioGroup>

          <GridItem bgColor="#0000001F" text="Acitivity" fontSize={'12px'} />
          <RadioGroup
            name={'activity'}
            row
            sx={{
              width: '75%',
              display: 'flex',
              justifyContent: 'space-around',
            }}
            onChange={onChange}
          >
            <StyledFormControlLabel
              value={0}
              label={'Lying quietly, normal position, moves easily'}
              control={<Radio />}
            />
            <StyledFormControlLabel
              value={1}
              label={'Squirming, shifting back and forth, tense'}
              control={<Radio />}
            />
            <StyledFormControlLabel
              value={2}
              label={'Ached, rigid, or jerking'}
              control={<Radio />}
            />
          </RadioGroup>

          <GridItem bgColor="#0000001F" text="Cry" fontSize={'12px'} />
          <RadioGroup
            name={'cry'}
            row
            sx={{
              width: '75%',
              display: 'flex',
              justifyContent: 'space-around',
            }}
            onChange={onChange}
          >
            <StyledFormControlLabel
              value={0}
              label={'No crying(awake or asleep)'}
              control={<Radio />}
            />
            <StyledFormControlLabel
              value={1}
              label={'Moans or whimpers; occasional complaint'}
              control={<Radio />}
            />
            <StyledFormControlLabel
              value={2}
              label={'Crying steadily, screams or sobs, frequent complaints'}
              control={<Radio />}
            />
          </RadioGroup>

          <GridItem
            bgColor="#0000001F"
            text="Consolability"
            fontSize={'12px'}
          />
          <RadioGroup
            name={'consolability'}
            row
            sx={{
              width: '75%',
              display: 'flex',
              justifyContent: 'space-around',
            }}
            onChange={onChange}
          >
            <StyledFormControlLabel
              value={0}
              label={'Content, Relaxed'}
              control={<Radio />}
            />
            <StyledFormControlLabel
              value={1}
              label={
                'Reassured by occasional touching, hugging, or being talked to; distractible'
              }
              control={<Radio />}
            />
            <StyledFormControlLabel
              value={2}
              label={'Difficult to console or comfort'}
              control={<Radio />}
            />
          </RadioGroup>
        </Grid>
        <Grid item xs={12}>
          <Box
            display={'flex'}
            flexDirection={'column'}
            alignItems={'flex-end'}
            sx={{ marginTop: '20px' }}
          >
            <Typography
              gutterBottom
              minWidth={115}
              fontWeight={700}
              variant="subtitle1"
            >
              Total: {sumValue}
            </Typography>
            <Typography
              minWidth={115}
              variant="caption"
              sx={{ color: `${theme.palette.primary.main}` }}
            >
              <Typography variant="inherit">
                <Box component={'strong'} mr={0.5}>
                  0 score:
                </Box>
                No pain
              </Typography>
              <Typography variant="inherit">
                <Box component={'strong'} mr={0.5}>
                  1~3 scores
                </Box>
                A little uncomfortable
              </Typography>
              <Typography variant="inherit">
                <Box component={'strong'} mr={0.5}>
                  4~6 scores
                </Box>
                Moderately uncomfortable
              </Typography>
              <Typography variant="inherit">
                <Box component={'strong'} mr={0.5}>
                  7~10 scores
                </Box>
                Very uncomfortable
              </Typography>
            </Typography>
          </Box>
        </Grid>
      </Box>
    </>
  );
};

export default FlaccContents;
