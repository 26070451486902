import { useState } from 'react';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableHead,
  TableRow,
  InputAdornment,
  useTheme,
} from '@mui/material';
import {
  StyledTableCellWithoutLeft,
  StyledTableCellWithoutLeftRight,
} from 'routes/Main/style';
import Form from 'components/Form';

import useTableForm from '../../hooks/useTableForm';
import { IFormValues, IFormWatch, IFormRegister } from 'routes/Main/type';

import { ReactComponent as MMSE9pic } from 'assets/MMSE_9_pic.svg';

const contentLabel = [
  {
    id: 1,
    ko: 'Category',
    desc: [
      '1. Score 1 point for each correct answer.',
      '2. Score 1 point for each correct answer.',
      '3. “I’m going to name three words/objects and you need to repeat them. Then remember them because I’m going to ask you to name them again later.”',
    ],
    scores: [5, 5, 3],
    ids: [0, 1, 2],
  },
  {
    id: 2,
    ko: 'Registration',
    desc: ['4. “Spell WORLD backwards” Answer: D-L-R-O-W'],
    scores: [5],
    ids: [3],
  },
  {
    id: 3,
    ko: 'Attention',
    desc: [
      '5. “Now, name the three objects/words I asked you to remember.” (Give one point for each.)',
    ],
    scores: [3],
    ids: [4],
  },
  {
    id: 4,
    ko: 'Language',
    desc: [
      '6. “What object is this?” Show a wrist watch.',
      '7. “What object is this?” Show a pencil.',
      '8. “Repeat this phrase: No ifs, ands, or buts”',
      '9. “Read the words and then do what it says.” (Give the patient/client a sheet of paper with CLOSE YOUR EYES written on it.)',
    ],
    scores: [1, 1, 1, 1],
    ids: [5, 6, 7, 8],
  },
  {
    id: 5,
    ko: 'Recall',
    desc: [
      '10. “Take the paper in your right/left hand, fold it in half, and put it on the floor.”',
    ],
    scores: [3],
    ids: [9],
  },
  {
    id: 6,
    ko: 'Understanding\n/Judgement',
    desc: [
      '11. “Make up and write a complete sentence on a piece paper.” (Sentence must contain a verb and noun.)',
      '12. “Copy this design, please.”',
    ],
    scores: [1, 1],
    ids: [10, 11],
  },
];

const questions1 = [
  '“What year is this?”',
  '“What is the current season?”',
  '“What month is this”',
  '“What’s the date today?”',
  '“What day of the week it is?” ',
];

const questions2 = [
  '“Which country are we in right now?”',
  '“What state/province are we in?”',
  '“What city or town are we in?”',
  '“What’s the street address of your home / What’s the name of this building?”',
  '“On which floor are we located / In which room are currently located?”',
];

const scoreLabel = [
  { score: '30-26', label: 'Normal (Could Be Normal)' },
  { score: '25-20', label: 'Mild (Early)' },
  { score: '19-10', label: 'Moderate (Middle) ' },
  { score: '9-0', label: 'Severe (Late)' },
];

interface Props extends IFormValues, IFormWatch, IFormRegister {
  disabled?: boolean;
}

const MMSEContents = (props: Props) => {
  const { palette } = useTheme();
  const { disabled, getValues, register } = props;

  const min = 0;
  const max = 5;

  const [input1, setInput1] = useState(
    getValues('score[0]') ? Number(getValues('score[0]')) : 0
  );
  const [input2, setInput2] = useState(
    getValues('score[1]') ? Number(getValues('score[1]')) : 0
  );
  const [input3, setInput3] = useState(
    getValues('score[2]') ? Number(getValues('score[2]')) : 0
  );
  const [input4, setInput4] = useState(
    getValues('score[3]') ? Number(getValues('score[3]')) : 0
  );
  const [input5, setInput5] = useState(
    getValues('score[4]') ? Number(getValues('score[4]')) : 0
  );
  const [input6, setInput6] = useState(
    getValues('score[5]') ? Number(getValues('score[5]')) : 0
  );
  const [input7, setInput7] = useState(
    getValues('score[6]') ? Number(getValues('score[6]')) : 0
  );
  const [input8, setInput8] = useState(
    getValues('score[7]') ? Number(getValues('score[7]')) : 0
  );
  const [input9, setInput9] = useState(
    getValues('score[8]') ? Number(getValues('score[8]')) : 0
  );
  const [input10, setInput10] = useState(
    getValues('score[9]') ? Number(getValues('score[9]')) : 0
  );
  const [input11, setInput11] = useState(
    getValues('score[10]') ? Number(getValues('score[10]')) : 0
  );
  const [input12, setInput12] = useState(
    getValues('score[11]') ? Number(getValues('score[11]]')) : 0
  );

  const total =
    input1 +
    input2 +
    input3 +
    input4 +
    input5 +
    input6 +
    input7 +
    input8 +
    input9 +
    input10 +
    input11 +
    input12;

  return (
    <Box sx={{ width: 'max-content', margin: '50px auto' }}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <StyledTableCellWithoutLeftRight
              align="left"
              sx={{ padding: '16px' }}
            >
              Questions
            </StyledTableCellWithoutLeftRight>
            <StyledTableCellWithoutLeftRight
              align="center"
              sx={{ padding: '16px' }}
            >
              Score
            </StyledTableCellWithoutLeftRight>
          </TableRow>
        </TableHead>
        <TableBody>
          {contentLabel.map(
            (
              content: {
                id: number;
                ko: string;
                desc: string[];
                scores: number[];
                ids: number[];
              },
              _
            ) => (
              <TableRow>
                <StyledTableCellWithoutLeftRight>
                  {content.desc.map((v, i) => {
                    return (
                      <TableRow
                        sx={{
                          height: '48px',
                          lineHeight: '48px',
                          borderBottom:
                            i !== content.desc.length - 1
                              ? '1px solid lightgray'
                              : '',
                        }}
                      >
                        <Box
                          sx={{
                            paddingLeft: '16px',
                            minWidth: '1100px',
                            fontSize: '14px',
                          }}
                        >
                          {v}
                          <br />
                          {v === '12. “Copy this design, please.”' && (
                            <MMSE9pic />
                          )}
                          {v ===
                            '1. Score 1 point for each correct answer.' && (
                            <Box
                              sx={{
                                marginTop: '-13px',
                                marginBottom: '11px',
                              }}
                            >
                              {questions1.map(a => (
                                <Typography fontSize={'14px'}>• {a}</Typography>
                              ))}
                            </Box>
                          )}
                          {v ===
                            '2. Score 1 point for each correct answer.' && (
                            <Box
                              sx={{ marginTop: '-15px', marginBottom: '9.5px' }}
                            >
                              {questions2.map(a => (
                                <Typography fontSize={'14px'}>• {a}</Typography>
                              ))}
                            </Box>
                          )}
                          {v ===
                            '3. “I’m going to name three words/objects and you need to repeat them. Then remember them because I’m going to ask you to name them again later.”' && (
                            <Typography
                              sx={{
                                fontSize: '14px',
                                margin: '-18px 0 10px 13px',
                              }}
                            >
                              (EX: BALL - CAR - MAN / APPLE - PENNY - TABLE)
                            </Typography>
                          )}
                          {v ===
                            '10. “Take the paper in your right/left hand, fold it in half, and put it on the floor.”' && (
                            <Typography
                              sx={{
                                fontSize: '14px',
                                margin: '-18px 0 10px 13px',
                              }}
                            >
                              (Give the patient/client a piece of paper and
                              score 1 for each action taken.)
                            </Typography>
                          )}
                        </Box>
                      </TableRow>
                    );
                  })}
                </StyledTableCellWithoutLeftRight>
                <StyledTableCellWithoutLeftRight>
                  {content.scores.map((score, i) => (
                    <TableRow
                      sx={{
                        height:
                          content.id === 6 && i === 1
                            ? '171.5px'
                            : (content.id === 1 && i === 0) ||
                              (content.id === 1 && i === 1)
                            ? '146px'
                            : (content.desc[2] && content.id === 1) ||
                              content.desc[5]
                            ? '58px'
                            : '48px',
                        lineHeight:
                          content.id === 6 && i === 1
                            ? '171.5px'
                            : (content.id === 1 && i === 0) ||
                              (content.id === 1 && i === 1)
                            ? '146px'
                            : (content.desc[2] && content.id === 1) ||
                              content.desc[5]
                            ? '58px'
                            : '48px',
                        textAlign: 'center',
                        borderBottom:
                          i !== content.desc.length - 1
                            ? '1px solid lightgray'
                            : '',
                      }}
                    >
                      <Box
                        sx={{
                          width: '120px',
                          height:
                            content.id === 6 && i === 1
                              ? '171.5px'
                              : (content.id === 1 && i === 0) ||
                                (content.id === 1 && i === 1)
                              ? '148px'
                              : (content.desc[2] && content.id === 1) ||
                                content.desc[5]
                              ? '58px'
                              : '48px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Form.MuiTextField
                          disabled={disabled}
                          required={false}
                          type="number"
                          inputProps={{ min, max }}
                          value={
                            content.desc[i].includes('1. Score')
                              ? input1
                              : content.desc[i].includes(String('2. Score'))
                              ? input2
                              : content.desc[i].includes(String('3.'))
                              ? input3
                              : content.desc[i].includes(String('4.'))
                              ? input4
                              : content.desc[i].includes(String('5.'))
                              ? input5
                              : content.desc[i].includes(String('6.'))
                              ? input6
                              : content.desc[i].includes(String('7.'))
                              ? input7
                              : content.desc[i].includes(String('8.'))
                              ? input8
                              : content.desc[i].includes(String('9.'))
                              ? input9
                              : content.desc[i].includes(String('10.'))
                              ? input10
                              : content.desc[i].includes(String('11.'))
                              ? input11
                              : content.desc[i].includes(String('12.'))
                              ? input12
                              : 0
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                /{score}
                              </InputAdornment>
                            ),
                          }}
                          {...register(`score[${content.ids[i]}]`, {
                            onChange: e => {
                              var value = parseInt(e.target.value, 10);
                              // console.log('✅', content.desc[i]);

                              let max = { score };

                              if (value >= max.score) value = max.score;
                              if (value < min) value = min;

                              if (content.desc[i].includes('1. Score'))
                                setInput1(value);
                              else if (content.desc[i].includes('2. Score'))
                                setInput2(value);
                              if (content.desc[i].includes('3.'))
                                setInput3(value);
                              if (content.desc[i].includes('4.'))
                                setInput4(value);
                              if (content.desc[i].includes('5.'))
                                setInput5(value);
                              if (content.desc[i].includes('6.'))
                                setInput6(value);
                              if (content.desc[i].includes('7.'))
                                setInput7(value);
                              if (content.desc[i].includes('8.'))
                                setInput8(value);
                              if (content.desc[i].includes('9.'))
                                setInput9(value);
                              if (content.desc[i].includes('10.'))
                                setInput10(value);
                              if (content.desc[i].includes('11.'))
                                setInput11(value);
                              if (content.desc[i].includes('12.'))
                                setInput12(value);
                            },
                          })}
                          // {...register(`score[${content.ids[i]}]`)}
                        />
                      </Box>
                    </TableRow>
                  ))}
                </StyledTableCellWithoutLeftRight>
              </TableRow>
            )
          )}
        </TableBody>
      </Table>
      <Box
        display={'flex'}
        flexDirection={'column'}
        alignItems={'flex-end'}
        sx={{ marginTop: '20px' }}
      >
        <Typography
          gutterBottom
          minWidth={115}
          fontWeight={700}
          variant="subtitle1"
        >
          Total : {total} point
        </Typography>
        <Typography
          minWidth={115}
          variant="caption"
          sx={{ color: `${palette.primary.main}` }}
        >
          {scoreLabel.map(({ score, label }) => (
            <Typography variant="inherit">
              <Box component={'strong'} mr={0.5}>
                {label} :
              </Box>
              {score}
            </Typography>
          ))}
        </Typography>
      </Box>
    </Box>
  );
};

export default MMSEContents;
