import { Fragment, useState } from 'react';
import { Grid } from '@mui/material';

import Form from 'components/Form';
import MuiTable from 'components/MuiTable';
import { IFormRegister } from 'routes/Main/type';

import RowContainer from '../components/RowContainer';
import SectionTitle from '../components/SectionTitle';

interface Props extends IFormRegister {
  disabled?: boolean;
}

const OutPatients = (props: Props) => {
  const { disabled, register } = props;

  const columns = [
    { fieldId: 'department', label: 'Department' },
    { fieldId: 'doctor', label: 'Doctor' },
    { fieldId: 'location', label: 'Place' },
    { fieldId: 'call_number', label: 'Phone Number' },
    { fieldId: 'date', label: 'Reservation Date' },
  ];

  const rows = Array.from({ length: 2 }, (_, i) => {
    const prefix = `out_patients.${i}`;
    return {
      id: i,
      department: (
        <Form.MuiTextField
          disabled={disabled}
          {...register(`${prefix}.department`)}
        />
      ),
      doctor: (
        <Form.MuiTextField
          disabled={disabled}
          {...register(`${prefix}.doctor`)}
        />
      ),
      location: (
        <Form.MuiTextField
          disabled={disabled}
          {...register(`${prefix}.location`)}
        />
      ),
      call_number: (
        <Form.MuiTextField
          type="tel"
          disabled={disabled}
          {...register(`${prefix}.call_number`)}
        />
      ),
      date: (
        <Form.MuiTextField
          type="date"
          InputLabelProps={{ shrink: true }}
          required={false}
          disabled={disabled}
          {...register(`${prefix}.date`)}
        />
      ),
    };
  });

  return (
    <Fragment>
      <SectionTitle title="OPD Reservation" />

      <RowContainer xs={12}>
        <Grid item xs={12}>
          <MuiTable columns={columns} rows={rows} />
        </Grid>
      </RowContainer>
    </Fragment>
  );
};

export default OutPatients;
