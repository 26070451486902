import { Grid, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';

import { useSnackbar } from 'notistack';

import MuiDialog from 'components/MuiDialog';
import {
  SurveyDialogProps,
  TTakingOverDefaultValues,
} from 'routes/Main/Survey/type';
import useSurvey from 'store/survey/useSurvey';
import useNotification from 'hooks/useNotification';

import CommonPatientInfo from '../components/CommonPatientInfo';
import TextareaSection from '../components/TextAreaSection';

import { updateTakingOver } from 'apis/survey';

const TakingOver = (props: SurveyDialogProps<TTakingOverDefaultValues>) => {
  const {
    title,
    isOpen,
    disabled,
    defaultValues,
    user_id,
    nurseName,
    patientInfo,
    onClose,
  } = props;

  const { onUpdateIsSave } = useSurvey();
  const { onSuccess, onFail, onResultCode, onRequired } = useNotification();

  const { handleSubmit, register, getValues, setValue, watch } = useForm({
    defaultValues,
  });

  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = (data: TTakingOverDefaultValues) => {
    const {
      loc,
      vital_sign,
      current_condition,
      rbfi,
      reasons,
      intervention,
      other,
    } = data;

    const request = {
      user_id,
      patient_id: patientInfo.patient_id,
      take_over_survey: { ...data },
    };

    updateTakingOver(request)
      .then(({ data: { rc } }) => {
        if (rc !== 1) return onResultCode(rc);

        onUpdateIsSave(true);
        onSuccess('Saved successfully');
      })
      .catch(e => onFail('Failed to save', e));
  };

  const formProps = {
    disabled,
    watch,
    register,
    getValues,
    setValue,
    onSuccess,
    onRequired,
  };

  return (
    <MuiDialog.SurveyForm
      title={title}
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={disabled ? undefined : handleSubmit(onSubmit)}
      update_at={defaultValues?.update_at}
    >
      <Grid
        container
        wrap="wrap"
        rowSpacing={5}
        columnSpacing={3}
        sx={{ py: 5, px: 1 }}
      >
        <Typography
          sx={{
            margin: '40px auto 0px auto',
            fontWeight: '700',
            fontSize: '16px',
            textAlign: 'center',
          }}
        >
          Nurse Take Over
        </Typography>
        <CommonPatientInfo patientInfo={patientInfo} nurseName={nurseName} />
        <TextareaSection
          {...formProps}
          title="Level of Consciousness (LOC)"
          registerId="loc"
        />
        <TextareaSection
          {...formProps}
          title="Vital Sign (specific)"
          registerId="vital_sign"
        />
        <TextareaSection
          {...formProps}
          title="Current Condition (POD, Treatment, Current Pain Assessment, Catheter, Medication, Therapeutic Diet, etc)"
          registerId="current_condition"
        />
        <TextareaSection
          {...formProps}
          title="Restraints / Pressure sore / Fall risk / Isolation"
          registerId="rbfi"
        />
        <TextareaSection
          {...formProps}
          title="Reasons for hospitalization / Medical history / Comorbidity / Allergy"
          registerId="reasons"
        />
        <TextareaSection
          {...formProps}
          title="Nursing Intervention (Examinations, Treatment, Request for other departments, etc)"
          registerId="intervention"
        />
        <TextareaSection
          {...formProps}
          title="Other important notes"
          registerId="other"
        />
      </Grid>
    </MuiDialog.SurveyForm>
  );
};

export default TakingOver;
